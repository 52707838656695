import * as actionTypes from "../actionTypes";
export type quizStateType = {
  mcqs: any[];
  loading: boolean;
  sections: any[];
  isSubmitted: boolean;
  subjects: any;
  submitting: boolean;
  score: number;
  showScore: boolean;
  showSuccess: boolean;
};
const initState: quizStateType = {
  mcqs: [],
  loading: false,
  sections: [],
  score: 0,
  isSubmitted: false,
  submitting: false,
  showScore: false,
  showSuccess: false,
  subjects: {
    CSIT: ["Math", "English", "Physics", "Chemistry"],
    BCA: ["Verbal Ability", "Quantitative Ability", "General Knowledge"],
    CMAT: [
      "Verbal Ability",
      "Quantitative Ability",
      "Logical Reasoning",
      "General Knowledge",
    ],
    BIT: [
      "Verbal Ability",
      "Quantitative Ability",
      "General Knowledge",
      "General IT",
    ],
  },
};
const quizReducer = (state: quizStateType = initState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.GET_MCQ_REQUEST:
      return { ...state, loading: true, mcqs: [] };
    case actionTypes.GET_MCQ_SUCCESS:
      return { ...state, loading: false, mcqs: payload };
    case actionTypes.GET_MCQ_FAILED:
      return { ...state, loading: true, mcqs: [] };
    case actionTypes.GET_SECTION_REQUEST: {
      return { ...state, loading: true, sections: payload };
    }
    case actionTypes.GET_SECTION_SUCCESS: {
      return { ...state, loading: false, sections: payload };
    }
    case actionTypes.GET_SECTION_FAILED:
      return { ...state, loading: true, sections: [] };
    case actionTypes.UPDATE_ANSWER_SHEET: {
      const { option, id } = payload;
      const mcqArray = [...state.mcqs];
      const index = mcqArray.findIndex((mcq) => mcq.id === id);
      mcqArray[index].is_choosed = true;
      mcqArray[index].choosed_option = option;
      return { ...state, mcqs: mcqArray };
    }
    case actionTypes.SUBMIT_TRUE:
      return { ...state, isSubmitted: true };
    case actionTypes.SUBMIT_FALSE:
      return { ...state, isSubmitted: false };
    case actionTypes.UPDATE_CORRECT_REQUEST:
      return {
        ...state,
        submitting: true,
        isSubmitted: false,
        showScore: false,
      };
    case actionTypes.UPDATE_CORRECT_FAILED:
      return { ...state, submitting: false, showScore: false };
    case actionTypes.UPDATE_CORRECT_SUCCESS: {
      const mcqArray = [...state.mcqs];
      Object.keys(payload).forEach((item) => {
        mcqArray.forEach((mcq, index) => {
          if (parseInt(item) === mcq.id) {
            mcqArray[index].is_correct = true;
          }
        });
      });
      return {
        ...state,
        mcqs: [...mcqArray],
        score: Object.keys(payload).length,
        submitting: false,
        showScore: true,
      };
    }
    case actionTypes.HIDE_SCORE:
      return { ...state, showScore: false, isSubmitted: true };
    case actionTypes.SHOW_SUCCESS:
      return { ...state, showSuccess: true };
    case actionTypes.HIDE_SUCCESS:
      return { ...state, showSuccess: false };
    case actionTypes.SET_SUBMITTING: {
      return { ...state, submitting: payload };
    }

    default:
      return state;
  }
};
export default quizReducer;
