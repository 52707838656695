import React from "react";
import styled from "@emotion/styled";
import { motion } from "framer-motion";
type propTypes = {
  image?: any;
  title: string;
};
const CourseItem: React.FC<propTypes> = ({ title, image, children }) => {
  return (
    <Course
      whileHover={{ originY: 0, scale: 1.1 }}
      transition={{ type: "spring", duration: 0.2 }}
    >
      {image && <img src={image} alt={title} />}
      <span>{children}</span>
    </Course>
  );
};

export default CourseItem;
const Course = styled(motion.div)`
  border: 2px solid #e5e5e5;
  background: #ffffff;
  height: 7rem;
  width: 7rem;
  border-radius: 0.6rem;
  display: flex;
  justify-content: center;
  transition: all 0.2s linear;
  position: relative;
  align-items: center;
  box-shadow: 0 0 0 1.5px #0e62ac66;
  @media only screen and (max-width: 567px) {
    width: 100%;
  }
  img {
    height: 80%;
    width: 80%;
    position: absolute;
    z-index: 1;
    object-fit: contain;
    vertical-align: middle;
  }
  span {
    font-size: 1.6rem;
    font-weight: 600;
    color: #000000;
    transition: color 0.2s linear;
    text-transform: uppercase;
    z-index: 2;
  }
  &:hover {
    border: 2px solid #0e62ac;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    span {
      color: #0e62ac;
    }
  }
`;
