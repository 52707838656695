import React, { useEffect } from "react";
import * as actionTypes from "../../redux/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import * as userAction from "../../redux/actions/user";
import Layout from "../Layout";
import { State } from "../../redux/rootReducers";
import { courseStateType } from "../../redux/reducers/course";
import { useNavigate } from "react-router-dom";
import { userStateType } from "../../redux/reducers/user";
const PrivateLayout: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const course = useSelector<State, courseStateType>((state) => state.course);
  const { type } = course;
  const userState = useSelector<State, userStateType>((state) => state.user);
  const { loggedOut } = userState;
  const authenticate = async () => {
    if (userAction.getToken()) {
      const res: any = await dispatch(userAction.getUserProfile());
      if (res?.status === 200) {
        return;
      } else {
        dispatch({ type: actionTypes.SHOW_LOGIN });
        navigate(`/${type}`);
      }
    } else {
      dispatch({ type: actionTypes.SHOW_LOGIN });
      navigate(`/${type}`);
    }
  };
  useEffect(() => {
    if (loggedOut) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedOut]);
  useEffect(() => {
    authenticate();
  }, []);
  return <Layout>{children}</Layout>;
};

export default PrivateLayout;
