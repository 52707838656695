import { Link } from "react-router-dom";
import React from "react";
import styles from "./style.module.scss";
import logo from "../../assets/images/logo_flat.png";
interface LogoProps {
  height?: string;
}
const Logo: React.FC<LogoProps> = ({ height }) => {
  return (
    <div className={styles.logoContainer}>
      <Link to="/">
        <img
          src={logo}
          alt="Binary Entrance"
          style={{ height: height ? height : "30px", width: "auto" }}
        />
      </Link>
    </div>
  );
};
export default Logo;
