import { college } from "../@types/college";

export const sortByRating = (a: college, b: college) => {
  if (a.rating! < b.rating!) {
    return 1;
  } else if (a.rating! > b.rating!) {
    return -1;
  }
  return 0;
};
