/* eslint-disable import/first */
import { Box, Flex } from "@chakra-ui/layout";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, EffectCoverflow, Pagination } from "swiper";
SwiperCore.use([Pagination, Autoplay, EffectCoverflow]);
import styled from "@emotion/styled";
// import styles from "../../styles/index.module.scss";
import Navbar from "../../components/Navbar";
import HeroSlider from "../../components/HeroSlider";
import { IoBulbOutline } from "react-icons/io5";
// import BlogItem from "../../components/BlogItem";
import CourseItem from "../../components/CourseItem";
import { motion } from "framer-motion";
// import EventItem from "../../components/EventItem";
import bg1 from "../../assets/images/button_bg1.png";
import bg2 from "../../assets/images/button_bg2.png";

import Layout from "../../components/Layout";
import "swiper/swiper-bundle.css";
import hero_image from "../../assets/images/1.jpg";
import { Link } from "react-router-dom";
import Gallery from "../../components/Gallery";
import Footer from "../../components/Footer";
import { useSelector } from "react-redux";
import { State } from "../../redux/rootReducers";
import { galleryStateType } from "../../redux/reducers/gallery";
import { Button } from "@chakra-ui/button";
import { AiOutlineForm } from "react-icons/ai";

const Home: React.FC = () => {
  const galleryState = useSelector<State, galleryStateType>(
    (state) => state.gallery
  );
  const randomIndex = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  };
  const { galleries } = galleryState;

  const datas: any[] = [
    {
      title: "B.Sc.CSIT | BCA | CMAT | BIT Entrance Preparation",
      description:
        "Get enrolled in best colleges all across Nepal with Binary Entrance. ",
    },
    {
      title: "Weekly Mock Test",
      description: "Boost your confidence by attending our weekly mock test.",
    },
    {
      title: "Entrance Preparation Book",
      description:
        "Our revised edition of entrance preparation books contains chapter wise theory, all past questions along with 10+ model sets.",
    },
    {
      title: "Run by Ascolians",
      description: "We are professional team ",
    },
  ];
  return (
    <Container>
      <Layout>
        <Navbar />
        <Box h="100vh">
          <HeroContainer>
            <motion.img
              initial={{ opacity: 0, y: 100 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              src={
                galleries?.[randomIndex(0, galleries?.length - 1)]?.image
                  ? galleries?.[randomIndex(0, galleries?.length - 1)]?.image
                  : hero_image
              }
              alt="Binary Entrance"
            />
            <motion.div
              initial={{ opacity: 0, y: "1.5rem" }}
              transition={{
                opacity: { duration: 0.4, ease: "linear" },
                y: { duration: 0.5, ease: "easeIn" },
              }}
              animate={{ y: ["2rem", "0rem"], opacity: [0, 1] }}
              className="contents"
            >
              <div className="relative-container">
                <motion.div
                  initial={{ opacity: 0.5 }}
                  animate={{ opacity: 1 }}
                  transition={{
                    delay: 0.3,
                    duration: 1,
                  }}
                  className="tip-container"
                >
                  <Flex
                    direction={{ base: "column", sm: "column", lg: "row" }}
                    alignItems={{
                      base: "flex-start",
                      sm: "flex-start",
                      lg: "center",
                    }}
                    justifyContent="space-between"
                    gridGap="10px"
                  >
                    <Flex
                      w="full"
                      gridGap="1rem"
                      justifyContent={{
                        base: "center",
                        sm: "center",
                        md: "flex-start",
                      }}
                    >
                      <div className="left">
                        <IoBulbOutline color="#0e62ac" size="2rem" />
                      </div>
                      <div className="right">
                        <h2 className="tips-title">
                          Experience the difference
                        </h2>
                        <p className="tips-content">
                          Your journey to bachelors begins here.
                        </p>
                      </div>
                    </Flex>
                    <Button
                      h="fit-content"
                      px="15px"
                      py="8px"
                      as={Link}
                      to="/mocktest/register"
                      minW="fit-content"
                      w={{ base: "full", sm: "full", md: "fit-content" }}
                      color="primary"
                      leftIcon={<AiOutlineForm />}
                    >
                      Physical Mocktest Registration
                    </Button>
                  </Flex>
                </motion.div>
                <Swiper
                  slidesPerView={1}
                  autoplay={{ delay: 4000, disableOnInteraction: false }}
                  speed={2500}
                  loop={true}
                  spaceBetween={50}
                  pagination={{ clickable: true, type: "bullets" }}
                >
                  {datas?.map((item, i) => (
                    <SwiperSlide key={i}>
                      <HeroSlider
                        title={item.title}
                        description={item.description}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </motion.div>
          </HeroContainer>
        </Box>
        <div className="divider"></div>
        <CourseSection id="course" className="section">
          <h1 className="section-title">Choose Courses</h1>
          <Grid className="course">
            <Link to="/csit">
              <CourseItem title="Csit" image={bg1}>
                Csit
              </CourseItem>
            </Link>
            <Link to="bca">
              <CourseItem title="Bca" image={bg2}>
                Bca
              </CourseItem>
            </Link>
            <Link to="cmat">
              <CourseItem title="Bca" image={bg1}>
                Cmat
              </CourseItem>
            </Link>
            <Link to="bit">
              <CourseItem title="Bit" image={bg2}>
                BIT
              </CourseItem>
            </Link>
          </Grid>
        </CourseSection>
        {/* <div className="divider"></div>
        <BlogSection className="section">
          <h1 className="section-title">Latest Blogs</h1>
          <Grid className="blog">
            <BlogItem />
            <BlogItem />
            <BlogItem />
            <BlogItem />
            <BlogItem />
          </Grid>
        </BlogSection> */}

        <div className="divider"></div>

        {/* <EventSection className="section">
          <h1 className="section-title">Events</h1>
          <Box pos="relative" mt="2rem"> */}
        {/* <IconButton
                aria-label="Event Previous Navigation"
                icon={<ChevronBackOutline color="#eee" />}
                borderRadius="50%"
                h="28px"
                w="28px"
                minW="28px"
                bg="#0e62ac"
                id="event-prev"
                pos="absolute"
                top="50%"
                border="none"
                transform="translateY(-50%)"
                left={-30}
                cursor="pointer"
                zIndex="5"
              /> */}
        {/* <Swiper
              slidesPerView={1}
              spaceBetween={30}
              speed={1000}
              autoplay={{ delay: 2500, disableOnInteraction: false }}
              navigation={{ nextEl: "#event-next", prevEl: "#event-prev" }}
              loop={true}
              breakpoints={{
                568: {
                  slidesPerView: 2,
                },
                968: {
                  slidesPerView: 3,
                },
              }}
            >
              <SwiperSlide key={1}>
                <EventItem />
              </SwiperSlide>
              <SwiperSlide key={2}>
                <EventItem />
              </SwiperSlide>
              <SwiperSlide key={3}>
                <EventItem />
              </SwiperSlide>
              <SwiperSlide key={4}>
                <EventItem />
              </SwiperSlide>
            </Swiper>
          </Box>
        </EventSection> */}
        {/* <div className="divider"></div> */}

        <Gallery />
        <div className="divider"></div>
        <Footer />
      </Layout>
    </Container>
  );
};
export default Home;
const Container = styled.div`
  width: 100%;
  .swiper-container {
    padding-bottom: 2.1rem !important;
    .swiper-pagination {
      bottom: 0rem !important;
      &-bullet {
        background: #eee;
        width: 12px;
        height: 12px;
        min-width: 12px;
        border-radius: 50%;
        transform: scale(0.8);
        transition: all 0.2s linear;
      }
      &-bullet-active {
        background: #eee;
        transition: all 0.2s linear;
      }
    }
  }
`;
const CourseSection = styled(motion.section)`
  max-width: 1600px;
  margin: 0 auto;
`;

const Grid = styled.div`
  display: grid;
  gap: 2rem;
  flex-wrap: wrap;
  margin-top: 2rem;
  &.course {
    grid-template-columns: repeat(auto-fit, minmax(7rem, 7rem));
    @media only screen and (max-width: 567px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  &.blog {
    grid-template-columns: repeat(3, 1fr);
    @media only screen and (max-width: 987px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media only screen and (max-width: 678px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;
const HeroContainer = styled.div`
  background: "#000000";
  user-select: none;
  overflow: hidden;
  position: relative;
  height: 100vh;
  max-width: 1600px;
  margin: 0 auto;
  z-index: 1;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.9) 5%,
      rgba(0, 0, 0, 0.5)
    );
    z-index: 2;
  }
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .contents {
    z-index: 3;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 0;
    background: linear-gradient(to bottom, #0e62acbb 100%, #eee);
    /* backdrop-filter: blur(25px); */
    color: #eee;

    .relative-container {
      position: relative;
      height: 100%;
      padding: 4.7rem 0rem 2.4rem;
      width: 100%;
      @media only screen and (max-width: 750px) {
        padding: 3.8rem 0 1.8rem;
      }
      @media only screen and (max-width: 567px) {
        padding: 2.5rem 0 1.4rem;
      }
      .tip-container {
        position: absolute;
        bottom: 90%;
        left: 3rem;
        right: 3rem;
        background: linear-gradient(to bottom, #eeeeee 95%, #0e62ac);
        padding: 1.6rem 3rem;
        border-radius: 0.7rem;
        color: #000000;
        .tips-title {
          font-size: 1.1rem;
          font-weight: 500;
          color: #0e62ac;
        }
        .tips-content {
          opacity: 0.8;
          font-size: 1rem;
        }
        @media only screen and (max-width: 750px) {
          padding: 1rem 2rem;
          left: 1.8rem;
          right: 1.8rem;
        }
        @media only screen and (max-width: 567px) {
          padding: 1rem;
          left: 1.5rem;
          right: 1.5rem;
        }
      }
    }
  }
`;

// const BlogSection = styled(motion.div)`
//   max-width: 1600px;
//   margin: 0 auto;
//   padding-top: 2rem;
// `;

// const EventSection = styled(motion.div)`
//   max-width: 1600px;
//   margin: 0 auto;
// `;
