import { Button } from "@chakra-ui/button";
import { FormControl } from "@chakra-ui/form-control";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { Image } from "@chakra-ui/image";
import {
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/input";
import { Center, Link, Stack, Text } from "@chakra-ui/layout";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import { chakra } from "@chakra-ui/system";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
type propsType = {
  isOpen: boolean;
};
/* eslint-disable import/first */
import { FaUserAlt, FaLock, FaPhoneAlt } from "react-icons/fa";
import { MdLocationOn, MdMailOutline } from "react-icons/md";
import { useDispatch } from "react-redux";
import { signUp } from "../../redux/actions/user";
import * as actionTypes from "../../redux/actionTypes";
import ReCAPTCHA from "react-google-recaptcha";
const CFaUserAlt = chakra(FaUserAlt);
const CFaLock = chakra(FaLock);
const CMdMail = chakra(MdMailOutline);
const CFaPhoneAlt = chakra(FaPhoneAlt);
const CMdLocationOn = chakra(MdLocationOn);
const SignUp: React.FC<propsType> = ({ isOpen }) => {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({ mode: "all" });
  // const [image, setImage] = useState<any>("");
  const handleClose = () => {
    dispatch({ type: actionTypes.HIDE_REGISTER });
    reset();
  };
  const [load, setLoad] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  // const handleChange = (evt: any) => {
  //   const reader = new FileReader();
  //   reader.onload = (e) => {
  //     setImage(e.target?.result);
  //   };
  //   reader.readAsDataURL(evt.target.files[0]);
  // };
  const onSubmit = async (values: any) => {
    const { first_name, email, last_name, phone, password, location } = values;
    const formData = new FormData();
    formData.set("first_name", first_name);
    formData.set("last_name", last_name);
    formData.set("email", `${email}`.toLowerCase());
    formData.set("phone", phone);
    formData.set("password", password);
    formData.set("location", location);
    // formData.set("image", image[0]);
    try {
      const response: any = await dispatch(signUp(formData));
      if (response.status === 200) {
        reset();
        dispatch({ type: actionTypes.HIDE_REGISTER });
        dispatch({ type: actionTypes.SHOW_LOGIN });
      }
    } catch (e) {
      return;
    }
  };
  const handleShowClick = () => {
    setShowPassword(!showPassword);
  };
  const handleShowLogin = () => {
    dispatch({ type: actionTypes.HIDE_REGISTER });
    dispatch({ type: actionTypes.SHOW_LOGIN });
  };
  const handleChange = (value: any) => {
    if (value) {
      setLoad(true);
    }
  };
  const handleExpire = () => {
    setLoad(false);
  };
  return (
    <Modal
      blockScrollOnMount={false}
      size="lg"
      isOpen={isOpen}
      onClose={handleClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Signup</ModalHeader>
        <ModalCloseButton />
        <Center>
          <Image
            borderRadius="full"
            boxSize="80px"
            src="https://bit.ly/3Fug2KS"
            alt="PP"
            fallbackSrc="https://bit.ly/3Fug2KS"
          />
        </Center>
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={4} p="1rem" backgroundColor="whiteAlpha.900">
              <FormControl mb="2">
                <InputGroup>
                  <InputLeftElement
                    top={1.5}
                    pointerEvents="none"
                    children={<CFaUserAlt h="full" color="gray.500" />}
                  />
                  <Input
                    fontSize={15}
                    py="6"
                    type="text"
                    placeholder="First Name *"
                    isInvalid={errors.first_name ? true : false}
                    errorBorderColor="red.500"
                    {...register("first_name", {
                      required: "Firstname is required",
                    })}
                    _placeholder={{ color: "var(--chakra-colors-gray-500)" }}
                    autoComplete="off"
                  />
                </InputGroup>
                {errors.first_name && (
                  <div className="error">{errors.first_name.message}</div>
                )}
              </FormControl>
              <FormControl mb="2">
                <InputGroup>
                  <InputLeftElement
                    top={1.5}
                    pointerEvents="none"
                    children={<CFaUserAlt h="full" color="gray.500" />}
                  />
                  <Input
                    fontSize={15}
                    py="6"
                    type="text"
                    placeholder="Last Name *"
                    isInvalid={errors.last_name ? true : false}
                    errorBorderColor="red.500"
                    {...register("last_name", {
                      required: "Lastname is required",
                    })}
                    _placeholder={{ color: "var(--chakra-colors-gray-500)" }}
                    autoComplete="off"
                  />
                </InputGroup>
                {errors.last_name && (
                  <div className="error">{errors.last_name.message}</div>
                )}
              </FormControl>
              <FormControl mb="2">
                <InputGroup>
                  <InputLeftElement
                    top={1.5}
                    pointerEvents="none"
                    children={<CMdMail h="full" size={17} color="gray.500" />}
                  />
                  <Input
                    fontSize={15}
                    py="6"
                    type="email"
                    placeholder="Email address*"
                    _placeholder={{ color: "var(--chakra-colors-gray-500)" }}
                    isInvalid={errors.email ? true : false}
                    errorBorderColor="red.500"
                    autoComplete="off"
                    {...register("email", {
                      required: "Email is required",
                      pattern: {
                        value:
                          /^([0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*@([0-9a-zA-Z][-\w]*[0-9a-zA-Z]\.)+[a-zA-Z]{2,3})$/,
                        message: "Email is invalid",
                      },
                    })}
                  />
                </InputGroup>
                {errors.email && (
                  <div className="error">{errors.email.message}</div>
                )}
              </FormControl>
              <FormControl mb="2">
                <InputGroup>
                  <InputLeftElement
                    top={1.5}
                    pointerEvents="none"
                    children={
                      <CFaPhoneAlt h="full" size={14} color="gray.500" />
                    }
                  />
                  <Input
                    fontSize={15}
                    py="6"
                    type="tel"
                    isInvalid={errors.phone ? true : false}
                    errorBorderColor="red.500"
                    placeholder="Phone *"
                    {...register("phone", {
                      required: "Phone is required",
                      pattern: {
                        value: /^\d{10}$/,
                        message:
                          "Phone number should be exact 10 digits number only",
                      },
                    })}
                    _placeholder={{ color: "var(--chakra-colors-gray-500)" }}
                    autoComplete="off"
                  />
                </InputGroup>
                {errors.phone && (
                  <div className="error">{errors.phone.message}</div>
                )}
              </FormControl>
              <FormControl mb="2">
                <InputGroup>
                  <InputLeftElement
                    top={1.5}
                    pointerEvents="none"
                    color="gray.300"
                    children={<CFaLock color="gray.500" />}
                  />
                  <Input
                    type={showPassword ? "text" : "password"}
                    placeholder="Password *"
                    _placeholder={{ color: "var(--chakra-colors-gray-500)" }}
                    fontSize={15}
                    py="6"
                    isInvalid={errors.password ? true : false}
                    errorBorderColor="red.500"
                    autoComplete="off"
                    {...register("password", {
                      required: "Password is required",
                      pattern: {
                        value: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/,
                        message:
                          "Must be at least one number, one lowercase, one uppercase letter and one special character ",
                      },
                      minLength: {
                        value: 8,
                        message: "Password should be at least 8 characters",
                      },
                    })}
                  />
                  <InputRightElement top={1.5} width="4.5rem">
                    <Button h="1.75rem" size="sm" onClick={handleShowClick}>
                      {showPassword ? (
                        <ViewIcon fontSize={15} color="gray.500" />
                      ) : (
                        <ViewOffIcon fontSize={15} color="gray.500" />
                      )}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                {errors.password && (
                  <div className="error">{errors.password.message}</div>
                )}
              </FormControl>
              <FormControl mb="5">
                <InputGroup>
                  <InputLeftElement
                    top={1.5}
                    pointerEvents="none"
                    children={
                      <CMdLocationOn h="full" color="gray.500" size={16} />
                    }
                  />
                  <Input
                    fontSize={15}
                    py="6"
                    type="text"
                    placeholder="Address *"
                    isInvalid={errors.first_name ? true : false}
                    errorBorderColor="red.500"
                    {...register("location", {
                      required: "Address is required",
                    })}
                    _placeholder={{ color: "var(--chakra-colors-gray-500)" }}
                    autoComplete="off"
                  />
                </InputGroup>
                {errors.location && (
                  <div className="error">{errors.location.message}</div>
                )}
              </FormControl>
              <FormControl>
                <ReCAPTCHA
                  theme="light"
                  sitekey={`${process.env.REACT_APP_CAPTCHA_SITE_KEY}`}
                  onChange={handleChange}
                  onErrored={handleExpire}
                  onExpired={handleExpire}
                />
              </FormControl>
              {/* <FormControl>
                <Text mb="8px">Profile Image *</Text>
                <Input
                  fontSize={15}
                  py="6"
                  isInvalid={errors.image ? true : false}
                  errorBorderColor="red.500"
                  type="file"
                  {...register("image", {})}
                  _placeholder={{ color: "var(--chakra-colors-gray-500)" }}
                  onChange={handleChange}
                  autoComplete="off"
                />
                {errors.image && (
                  <div className="error">{errors.image.message}</div>
                )}
              </FormControl> */}

              <Button
                borderRadius={0}
                type="submit"
                variant="solid"
                width="full"
                fontSize={15}
                py="6"
                backgroundColor="primary"
                color="whiteAlpha.800"
                rounded="md"
                _hover={{
                  color: "var(--chakra-colors-gray-500)",
                  backgroundColor: "#ddd",
                }}
                disabled={!load}
                isLoading={isSubmitting}
              >
                Register
              </Button>
              <Text fontSize="1rem" my="8">
                Already have an account ?{" "}
                <Link
                  fontWeight="500"
                  color="primary"
                  onClick={handleShowLogin}
                >
                  Login
                </Link>
              </Text>
            </Stack>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SignUp;
