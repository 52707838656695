import axios, { AxiosError } from "axios";
export default class Api {
  axiosFunction: any;
  constructor(setToken: boolean = true) {
    this.axiosFunction = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
    });
    if (setToken) {
      this.setToken();
    }
  }
  setToken = () => {
    this.axiosFunction.interceptors.request.use(
      (config: any) => {
        config.headers["Authorization"] = `Bearer ${localStorage.getItem(
          "token"
        )}`;
        return config;
      },
      (error: any) => {
        return Promise.reject(error);
      }
    );
  };
  get = (url: string, data: any) => {
    return this.axiosFunction
      .get(url, { params: data })
      .then((res: any) => res)
      .catch((e: AxiosError) => {
        throw e;
      });
  };
  post = (url: string, data: any, headers: any = null) => {
    if (headers) {
      for (const header in headers) {
        if (headers[header]) {
          this.axiosFunction.defaults.headers[header] = headers[header];
        }
      }
    }
    return this.axiosFunction
      .post(url, data)
      .then((res: any) => res)
      .catch((e: AxiosError) => {
        throw e;
      });
  };
  put = (url: string, data: any, headers: any = null) => {
    if (headers) {
      for (const header in headers) {
        if (headers[header]) {
          this.axiosFunction.defaults.headers[header] = headers[header];
        }
      }
    }
    return this.axiosFunction
      .put(url, data)
      .then((res: any) => res)
      .catch((e: AxiosError) => {
        throw e;
      });
  };
  patch = (url: string, data: any, headers: any = null) => {
    if (headers) {
      for (const header in headers) {
        if (headers[header]) {
          this.axiosFunction.defaults.headers[header] = headers[header];
        }
      }
    }
    return this.axiosFunction
      .patch(url, data)
      .then((res: any) => res)
      .catch((e: AxiosError) => {
        throw e;
      });
  };
  delete = (url: string, data: any, headers: any = null) => {
    if (headers) {
      for (const header in headers) {
        if (headers[header]) {
          this.axiosFunction.defaults.headers[header] = headers[header];
        }
      }
    }
    return this.axiosFunction
      .delete(url)
      .then((res: any) => res)
      .catch((e: AxiosError) => {
        throw e;
      });
  };
}
