import * as actionTypes from "../actionTypes";
export type courseStateType = {
  type: string;
};
const initState: courseStateType = {
  type: "",
};
const courseReducer = (state: courseStateType = initState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET_COURSE:
      return { ...state, type: payload };
    default:
      return state;
  }
};
export default courseReducer;
