import * as actionTypes from "../actionTypes";
export type noticeStateType = {
  notices: any[];
  singleNotice: any;
  error: boolean;
};
const initState: noticeStateType = {
  notices: [],
  singleNotice: {},
  error: false,
};
const noticeReducer = (state: noticeStateType = initState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET_NOTICE:
      return { ...state, notices: payload };
    case actionTypes.GET_SINGLE_NOTICE_REQUEST: {
      return {
        ...state,
        singleNotice: {},
        error: false,
      };
    }
    case actionTypes.GET_SINGLE_NOTICE_SUCCESS: {
      return {
        ...state,
        singleNotice: payload,
        error: false,
      };
    }
    case actionTypes.GET_SINGLE_NOTICE_FAILED: {
      return {
        ...state,
        singleNotice: {},
        error: true,
      };
    }
    default:
      return state;
  }
};
export default noticeReducer;
