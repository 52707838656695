import styled from "@emotion/styled";
import { motion } from "framer-motion";
const Nav = styled(motion.nav)`
  position: fixed;
  height: fit-content;
  width: 100%;
  top: 0;
  z-index: 10;

  &.active {
    background: #eee;
    box-shadow: 0px 0.1px 10px rgba(0, 0, 0, 0.25);
    transition: all 0.3s linear;
    backdrop-filter: blur(15px);
  }
  .nav-container {
    max-width: 1600px;
    height: 90px;
    margin: 0 auto;
    padding: 20px 3rem;
    /* line-height: 90px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    .menu-close,
    .menu-open {
      display: none;
    }
    .main-menu {
      position: relative;
      gap: 18px;
      align-items: center;
      flex-direction: row;
      @media only screen and (max-width: 750px) {
        position: fixed;
        top: 70px;
        left: 100%;
        right: 0;
        width: 0;
        opacity: 0;
        visibility: hidden;
        background: #fff;
        height: calc(100vh - 7.8rem);
        padding: 20px;
        gap: 30px;
        align-items: center;
        width: 100%;
        flex-direction: column;
        padding-top: 100px;
        overflow-x: hidden;
        overflow-y: auto;
      }
      &.active {
        @media only screen and (max-width: 750px) {
          left: 0;
          right: 0;
          width: 100%;
          opacity: 1;
          visibility: visible;
        }
      }
      @media only screen and (max-width: 673px) {
        top: 70px;
        height: calc(100vh - 70px);
      }
    }
    @media only screen and (max-width: 750px) {
      padding: 1.1rem 1.8rem;
      .menu-open {
        display: block;
      }
      height: 70px;
    }
    @media only screen and (max-width: 567px) {
      padding: 0.7rem 1.5rem;
    }
    &.show {
      .menu-open {
        display: none;
      }
      .menu-close {
        display: block;
      }
      @media only screen and (min-width: 750px) {
        .menu-close {
          display: none;
        }
      }
    }
  }
`;
export default Nav;
