import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import Navbar from "../../components/Navbar";
import * as quizAction from "../../redux/actions/quiz";
import { Box, Heading, Text } from "@chakra-ui/layout";
import "katex/dist/katex.min.css";
import { useNavigate, useParams } from "react-router-dom";
import { Spinner } from "@chakra-ui/spinner";
import { Button } from "@chakra-ui/button";
import { Input } from "@chakra-ui/input";

import Layout from "../../components/Layout";
import cogoToast from "cogo-toast";
import {
  Table,
  TableCaption,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/table";
import { BsArrowLeft } from "react-icons/bs";

const PhysicalMocktestResult: React.FC = () => {
  const navigate = useNavigate();
  const [results, setResults] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [data, setData] = useState<any>({});
  const [beid, setBeid] = useState<string | number>("");
  const { id } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const fetchResult = async () => {
    setLoading(true);
    try {
      const res = await quizAction.fetchSinglePhysicalMockTestResult(id as any);
      if (res?.status === 200 && res?.data?.display === true) {
        setData(res.data);
        setResults(JSON.parse(res.data?.result));
        setFilteredData(JSON.parse(res.data?.result));
      } else {
        setResults([]);
      }
    } catch (e) {
      setResults([]);
      setData({});
      cogoToast.error("Failed to get load results.", {
        position: "top-center",
      });
    } finally {
      setLoading(false);
    }
  };
  const handleChange = (e: any) => {
    setBeid(e.target.value);
    let data = [...results];
    data = data?.filter((item) => `${item.beid}`.includes(e.target.value));
    setFilteredData(data);
  };
  useEffect(() => {
    fetchResult();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Layout>
      <Navbar active="active" />
      <Container>
        <Box maxW="700px" mx="auto">
          <Box w="full" mb="15px">
            <Button
              bg="transparent"
              px="0"
              py="0"
              h="fit-content"
              leftIcon={
                <BsArrowLeft
                  fontSize="25px"
                  color="var(--chakra-colors-primary)"
                />
              }
              _hover={{ background: "transparent" }}
              onClick={() => navigate(-1)}
              minW="fit-content"
              color="primary"
            >
              Go back
            </Button>
          </Box>

          <div className="divider-md"></div>
          {loading ? (
            <Box d="flex" h="fit-content" gridGap="10px" alignItems="center">
              <Spinner
                thickness="4px"
                speed="0.75s"
                emptyColor="#0e62ac55"
                color="#0e62ac"
                h={{ base: "2rem", sm: "2rem", md: "2.5rem" }}
                w={{ base: "2rem", sm: "2rem", md: "2.5rem" }}
              />
              <Heading
                as="span"
                fontSize={{ base: "15px", sm: "15px", md: "1rem" }}
                fontWeight={500}
              >
                Getting result, Please wait...
              </Heading>
            </Box>
          ) : (
            <>
              <Text mb="20px" fontSize="20px">
                Result: {data?.title}
              </Text>
              <Box>
                <Input
                  fontSize="16px"
                  type="text"
                  py="20px"
                  value={beid}
                  boxShadow="0 0 0 1px #bbbbbb"
                  placeholder="Enter your BEID"
                  _placeholder={{ color: "var(--chakra-colors-gray-500)" }}
                  autoComplete="off"
                  onChange={handleChange}
                />
              </Box>
              <div className="divider-md"></div>
              <Table variant="striped">
                {filteredData?.length === 0 && (
                  <TableCaption>No result found</TableCaption>
                )}
                <Thead>
                  <Tr>
                    <Th textAlign="left">Rank</Th>
                    <Th textAlign="left">BEID</Th>
                    <Th textAlign="left">Course</Th>
                    <Th textAlign="left">Score</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {filteredData?.map((result, index) => (
                    <Tr>
                      <Td>{index + 1}</Td>
                      <Td>{result.beid}</Td>
                      <Td>{result.course}</Td>
                      <Td>{result.score}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </>
          )}
        </Box>
      </Container>
    </Layout>
  );
};

export default PhysicalMocktestResult;
const Container = styled.div`
  max-width: 1600px;
  margin: 90px auto 0;
  padding: 2rem 5rem;

  @media only screen and (max-width: 750px) {
    padding: 2rem 3rem;
    margin: 70px auto 0;
  }
  @media only screen and (max-width: 678px) {
    margin: 70px auto 0;
  }
  @media only screen and (max-width: 567px) {
    padding: 20px;
  }
`;

export const ScoreCard = styled(Box)`
  background: #ffffff;
  border-radius: 8px;
  padding: 15px 20px;
  .title {
    font-size: 20px;
    color: var(--chakra-colors-primary);
    font-weight: 500;
    margin-bottom: 8px;
    @media only screen and (max-width: 678px) {
      font-size: 18px;
    }
    @media only screen and (max-width: 567px) {
      font-size: 16px;
    }
  }
  .score {
    font-size: 17px;
    @media only screen and (max-width: 678px) {
      font-size: 16px;
    }
    @media only screen and (max-width: 567px) {
      font-size: 15px;
    }

    .bold {
      font-weight: 500;
    }
  }
`;
