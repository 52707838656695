import * as actionTypes from "../actionTypes";
export type userStateType = {
  isAuthenticated: boolean;
  isVerified: boolean;
  profile: any;
  loading: boolean;
  loggedOut: boolean;
};
const initState: userStateType = {
  isAuthenticated: false,
  isVerified: false,
  profile: {},
  loading: false,
  loggedOut: false,
};
const userReducer = (state: userStateType = initState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.LOGIN_REQUEST:
      return { ...state, loading: true };
    case actionTypes.LOGIN_SUCCESS:
      return { ...state, loading: false };
    case actionTypes.LOGIN_FAILED:
      return { ...state, loading: false };
    case actionTypes.REGISTER_REQUEST:
      return { ...state, loading: true };
    case actionTypes.REGISTER_SUCCESS:
      return { ...state, loading: false };
    case actionTypes.REGISTER_FAILED:
      return { ...state, loading: false };

    case actionTypes.GET_PROFILE_REQUEST: {
      return { ...state, loading: true };
    }
    case actionTypes.LOGOUT: {
      return {
        ...state,
        profile: {},
        isAuthenticated: false,
        isVerified: false,
      };
    }
    case actionTypes.GET_PROFILE_FAILED: {
      return {
        ...state,
        isAuthenticated: false,
        isVerified: false,
        profile: {},
        loading: false,
      };
    }
    case actionTypes.GET_PROFILE_SUCCESS: {
      return {
        ...state,
        isAuthenticated: true,
        isVerified: payload.verified,
        profile: payload,
        loading: false,
      };
    }
    case actionTypes.VERIFICATION_SUCCESS: {
      return {
        ...state,
        isVerified: true,
      };
    }
    case actionTypes.SET_LOGGED_OUT: {
      return {
        ...state,
        loggedOut: payload,
      };
    }
    default:
      return state;
  }
};
export default userReducer;
