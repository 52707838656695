export const SHOW_LOGIN: string = "SHOW_LOGIN";
export const SHOW_REGISTER: string = "SHOW_REGISTER";
export const HIDE_LOGIN: string = "HIDE_LOGIN";
export const HIDE_REGISTER: string = "HIDE_REGISTER";
export const SHOW_FORGET_PASSWORD: string = "SHOW_FORGET_PASSWORD";
export const HIDE_FORGET_PASSWORD: string = "HIDE_FORGET_PASSWORD";
export const SHOW_VERIFICATION: string = "SHOW_VERIFICATION";
export const HIDE_VERIFICATION: string = "HIDE_VERIFICATION";

//user
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGOUT = "LOGOUT";

export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILED = "REGISTER_FAILED";

export const GET_PROFILE_REQUEST = "GET_PROFILE_REQUEST";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_FAILED = "GET_PROFILE_FAILED";

export const SUBMIT_TRUE = "SUBMIT_TRUE";
export const SUBMIT_FALSE = "SUBMIT_FALSE";

export const UPDATE_ANSWER_SHEET = "UPDATE_ANSWER_SHEET";

export const UPDATE_CORRECT_REQUEST = "UPDATE_CORRECT_REQUEST";
export const UPDATE_CORRECT_SUCCESS = "UPDATE_CORRECT_SUCCESS";
export const UPDATE_CORRECT_FAILED = "UPDATE_CORRECT_FAILED";
// MCQ
export const GET_MCQ_REQUEST = "GET_MCQ_REQUEST";
export const GET_MCQ_SUCCESS = "GET_MCQ_SUCCESS";
export const GET_MCQ_FAILED = "GET_MCQ_FAILED";

export const HIDE_SCORE = "HIDE_SCORE";
export const VERIFICATION_SUCCESS = "VERIFICATION_SUCCESS";

export const MOCK_TEST_COMPLETE = "MOCK_TEST_COMPLETE";
// Model Sets
export const GET_SECTION_REQUEST: string = "GET_SECTION_REQUEST";
export const GET_SECTION_SUCCESS: string = "GET_SECTION_SUCCESS";
export const GET_SECTION_FAILED: string = "GET_SECTION_FAILED";
export const SET_GALLERY: string = "SET_GALLERY";
export const SHOW_VERIFY_ALERT: string = "SHOW_VERIFY_ALERT";
export const HIDE_VERIFY_ALERT: string = "HIDE_VERIFY_ALERT";
export const SET_COURSE = "SET_COURSE";

export const SET_NOTICE = "SET_NOTICE";
export const GET_SINGLE_NOTICE_REQUEST = "GET_SINGLE_NOTICE_REQUEST";
export const GET_SINGLE_NOTICE_SUCCESS = "GET_SINGLE_NOTICE_SUCCESS";
export const GET_SINGLE_NOTICE_FAILED = "GET_SINGLE_NOTICE_FAILED";

export const SET_BLOG = "SET_BLOG";
export const GET_SINGLE_BLOG_REQUEST = "GET_SINGLE_BLOG_REQUEST";
export const GET_SINGLE_BLOG_SUCCESS = "GET_SINGLE_BLOG_SUCCESS";
export const GET_SINGLE_BLOG_FAILED = "GET_SINGLE_BLOG_FAILED";

export const SHOW_SUCCESS = "SHOW_SUCCESS";
export const HIDE_SUCCESS = "HIDE_SUCCESS";

export const SET_SUBMITTING = "SET_SUBMITTING";
export const SET_LOGGED_OUT = "SET_LOGGED_OUT";
