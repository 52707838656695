import { Box, Link } from "@chakra-ui/layout";
import React from "react";
import {
  IoEarthOutline,
  IoLockClosedOutline,
  IoLocationOutline,
} from "react-icons/io5";
import { college } from "../../@types/college";
import CollegeCardItem from "./styled";

const CollegeCard: React.FC<college> = ({
  college_name,
  location,
  logo,
  website,
  type,
}) => {
  return (
    <CollegeCardItem>
      <div className="college-header-wrapper">
        <span className="college-logo-container">
          <img src={logo} className="college-logo" alt="amrit science campus" />
        </span>
        <div className="college-contents">
          <span className="college-name">
            <Link target="_blank" href={`${website}`}>
              {college_name}
            </Link>
            <br />
          </span>
          <Box mt="0.6rem">
            <span title={type}>
              {type === "Government" ? (
                <IoEarthOutline
                  height="1.5rem"
                  width="1.5rem"
                  color="#0e62ac"
                />
              ) : (
                <IoLockClosedOutline
                  height="1.5rem"
                  width="1.5rem"
                  color="#0e62ac"
                />
              )}
            </span>
          </Box>
        </div>
      </div>
      <div className="college-location-container">
        <IoLocationOutline height="1.5rem" width="1.5rem" color="#0e62ac" />
        <span>{location}</span>
      </div>
    </CollegeCardItem>
  );
};

export default CollegeCard;
