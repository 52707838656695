import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import Navbar from "../../components/Navbar";
import * as quizAction from "../../redux/actions/quiz";
import { State } from "../../redux/rootReducers";
import { useSelector } from "react-redux";
import { Box, Flex, Heading, List, ListItem, Text } from "@chakra-ui/layout";

import "katex/dist/katex.min.css";
import PrivateLayout from "../../components/PrivateLayout";
import { useNavigate } from "react-router-dom";
import { Spinner } from "@chakra-ui/spinner";
import { userStateType } from "../../redux/reducers/user";
import { Button } from "@chakra-ui/button";
import { BsArrowLeft } from "react-icons/bs";
// import { userStateType } from "src/redux/reducers/user";

const OnlineMocktestResult: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [results, setResults] = useState<any[]>([]);
  // const userState = useSelector<State, userStateType>((state) => state.user);
  const userState = useSelector<State, userStateType>((state) => state.user);
  const {
    profile: { BEID },
    isAuthenticated,
    isVerified,
  } = userState;
  const getResult = async (beid: string | number) => {
    setLoading(true);
    const res = await quizAction.mocktestResultCheck(beid);
    if (res?.status === 200) {
      setLoading(false);
      setResults(res.data);
    } else {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (BEID) {
      getResult(BEID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [BEID]);
  useEffect(() => {
    if (isAuthenticated && !isVerified) {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVerified, isAuthenticated]);
  return (
    <PrivateLayout>
      <Navbar active="active" />
      {isAuthenticated ? (
        <>
          <Container>
            <Box maxW="700px" mx="auto">
              <Box w="full" mb="15px">
                <Button
                  bg="transparent"
                  px="0"
                  py="0"
                  h="fit-content"
                  leftIcon={
                    <BsArrowLeft
                      fontSize="25px"
                      color="var(--chakra-colors-primary)"
                    />
                  }
                  _hover={{ background: "transparent" }}
                  onClick={() => navigate(-1)}
                  minW="fit-content"
                  color="primary"
                >
                  Go back
                </Button>
              </Box>
              <Text
                mb={{ base: "15px", sm: "15px", md: "20px" }}
                w="fit-content"
                as="h2"
                color="primary"
                fontSize={{ base: "14px", sm: "15px", md: "17px" }}
              >
                Mocktest Result(BEID:{BEID})
              </Text>

              {loading ? (
                <Box
                  d="flex"
                  h="fit-content"
                  gridGap="10px"
                  alignItems="center"
                >
                  <Spinner
                    thickness="4px"
                    speed="0.75s"
                    emptyColor="#0e62ac55"
                    color="#0e62ac"
                    h={{ base: "2rem", sm: "2rem", md: "2.5rem" }}
                    w={{ base: "2rem", sm: "2rem", md: "2.5rem" }}
                    minW={{ base: "2rem", sm: "2rem", md: "2.5rem" }}
                  />
                  <Heading
                    as="span"
                    fontSize={{ base: "15px", sm: "15px", md: "1rem" }}
                    fontWeight={500}
                  >
                    Getting your result, Please wait...
                  </Heading>
                </Box>
              ) : (
                <>
                  {results?.length === 0 && (
                    <Heading
                      as="span"
                      fontSize={{ base: "15px", sm: "15px", md: "1rem" }}
                      color="red.500"
                      fontWeight={500}
                    >
                      Your result not found.
                    </Heading>
                  )}
                  <List spacing={8}>
                    {results?.map((result, index) => (
                      <ListItem key={`${index}`}>
                        <ScoreCard>
                          <h2 className="title">Mock Test</h2>
                          <span className="score">
                            Your Score:{" "}
                            <span className="bold">{result.score}</span>
                          </span>
                        </ScoreCard>
                      </ListItem>
                    ))}
                  </List>
                </>
              )}
            </Box>
          </Container>
        </>
      ) : (
        <Container>
          <>
            <div className="divider-md"></div>
            <Box d="flex" gridGap="10px" alignItems="center">
              <Spinner
                thickness="4px"
                speed="0.75s"
                emptyColor="#0e62ac55"
                color="#0e62ac"
                h={{ base: "2rem", sm: "2rem", md: "2.5rem" }}
                w={{ base: "2rem", sm: "2rem", md: "2.5rem" }}
              />
              <Heading as="span" fontSize="1rem" fontWeight={500}>
                Authorizing user, Please wait...
              </Heading>
            </Box>
            <div className="divider-md"></div>
          </>
        </Container>
      )}
    </PrivateLayout>
  );
};

export default OnlineMocktestResult;
const Container = styled.div`
  max-width: 1600px;
  margin: 90px auto 0;
  padding: 2rem 5rem;

  @media only screen and (max-width: 750px) {
    padding: 2rem 3rem;
    margin: 70px auto 0;
  }
  @media only screen and (max-width: 678px) {
    margin: 70px auto 0;
  }
  @media only screen and (max-width: 567px) {
    padding: 20px;
  }
`;

export const ScoreCard = styled(Box)`
  background: #ffffff;
  border-radius: 8px;
  padding: 15px 20px;
  .title {
    font-size: 20px;
    color: var(--chakra-colors-primary);
    font-weight: 500;
    margin-bottom: 8px;
    @media only screen and (max-width: 678px) {
      font-size: 18px;
    }
    @media only screen and (max-width: 567px) {
      font-size: 16px;
    }
  }
  .score {
    font-size: 17px;
    @media only screen and (max-width: 678px) {
      font-size: 16px;
    }
    @media only screen and (max-width: 567px) {
      font-size: 15px;
    }
    .bold {
      font-weight: 500;
    }
  }
`;
