import React, { useState } from "react";
import styled from "@emotion/styled";
import { Button } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../redux/rootReducers";
import { userStateType } from "../../redux/reducers/user";
import * as actionTypes from "../../redux/actionTypes";
import { useNavigate } from "react-router-dom";
type Props = {
  title?: string;
  course?: string;
  started?: boolean;
  start_date?: string;
  end_date?: string;
};
const McqCard: React.FC<Props> = ({ title, course }) => {
  const navigate = useNavigate();
  const userState = useSelector<State, userStateType>((state) => state.user);
  const { isVerified } = userState;
  const dispatch = useDispatch();
  const handleStart = () => {
    if (isVerified) {
      navigate(`/test/${course}/${title}`);
    } else {
      dispatch({ type: actionTypes.SHOW_VERIFY_ALERT });
    }
  };
  return (
    <Box>
      <div className="top-container">
        <h1 className="mcq-title">
          {title ? `${title}`.split("-")[1] : "Model Set"}
        </h1>
        <div className="tags-container">
          <span className="tag">Practice set</span>
          <span className="tag">TU</span>
          <span className="tag">IOST</span>
          <span className="tag">{title ? title : ""}</span>
        </div>
      </div>
      <div className="bottom-container">
        <Button
          fontSize="16px"
          textTransform="uppercase"
          bg="#0e62ac"
          py={["17px", "18px"]}
          color="#eee"
          fontWeight={500}
          border="none"
          outline="none"
          w="full"
          fontFamily="inherit"
          _hover={{
            color: "var(--chakra-colors-white)",
          }}
          cursor="pointer"
          onClick={() => handleStart()}
        >
          Start now
        </Button>
      </div>
    </Box>
  );
};
const Box = styled.div`
  border: 2px solid #ddd;
  background: #e9e9e9;
  user-select: none;
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  height: fit-content;
  flex-direction: column;
  gap: 1.7rem;
  justify-content: space-between;
  max-width: 100%;
  min-height: 250px;
  .top-container {
    .mcq-title {
      font-size: 1.4rem;
      font-weight: 500;
      color: #0e62ac;
    }
    .tags-container {
      display: flex;
      gap: 5px;
      flex-wrap: wrap;
      margin-top: 25px;
      .tag {
        cursor: text;
        padding: 3px 8px;
        border-radius: 8px;
        font-size: 15px;
        font-weight: 500;
        background: #0e62ac44;
        color: #0e62ac;
      }
    }
  }
  @media only screen and (max-width: 567px) {
    max-width: 100%;
  }
  .bottom-container {
    display: flex;
    gap: 10px;
  }
`;
export default McqCard;
