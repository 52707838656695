import styled from "@emotion/styled";
const Layout = styled.section`
  width: 100%;
  .main-container {
    max-width: 1600px;
    margin: 90px auto 0 auto;
    padding: 0 4rem;
    min-height: 80vh;
    .notice-container {
      display: flex;
      gap: 20px;
      @media only screen and (max-width: 900px) {
        flex-direction: column;
      }
      .notice-list {
        flex: 1;
        padding: 15px 0px;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .notice-item {
          display: flex;
          width: 100%;
          align-items: center;
          gap: 8px;
          background-color: #ffffff;
          border: 1px solid transparent;
          padding: 15px 10px;
          border-radius: 3px;
          .notice-title {
            font-size: 16px;
            color: #0e62ac;
          }
          &:hover {
            transition: all 0.3s linear;
            background-color: #ffffff;
            border: 1px solid var(--chakra-colors-primary);
          }
        }
      }
      .right-item {
        width: 200px;
        /* height: 500px; */
        /* background-color: #cccccc; */
      }
    }
    .single-notice-container {
      max-width: 900px;
      margin: 0 auto;
    }
    @media only screen and (max-width: 750px) {
      padding: 0 2rem;
      margin: 70px auto 0 auto;
    }
    @media only screen and (max-width: 567px) {
      padding: 0 1.5rem;
    }
  }
`;
export default Layout;
