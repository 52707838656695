import { Button } from "@chakra-ui/button";
import { FormControl } from "@chakra-ui/form-control";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import {
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/input";
import { Link, Stack, Text } from "@chakra-ui/layout";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import { chakra } from "@chakra-ui/system";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
type propsType = {
  isOpen: boolean;
};
/* eslint-disable import/first */
import { FaUserAlt, FaLock } from "react-icons/fa";
import { useDispatch } from "react-redux";
import * as actionTypes from "../../redux/actionTypes";
const CFaUserAlt = chakra(FaUserAlt);
const CFaLock = chakra(FaLock);
import * as userAction from "../../redux/actions/user";
const Login: React.FC<propsType> = ({ isOpen }) => {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({ mode: "all" });
  const handleClose = () => {
    dispatch({ type: actionTypes.HIDE_LOGIN });
    reset();
  };
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleShowClick = () => {
    setShowPassword(!showPassword);
  };
  const handleShowSignUp = () => {
    dispatch({ type: actionTypes.SHOW_REGISTER });
    dispatch({ type: actionTypes.HIDE_LOGIN });
  };
  const onSubmit = async (values: any) => {
    const data = {
      ...values,
      email: `${values.email}`.toLowerCase(),
    };
    const res: any = await dispatch(userAction.login(JSON.stringify(data)));
    if (res.status === 200) {
      reset();
      dispatch(userAction.getUserProfile());
      dispatch({ type: actionTypes.HIDE_LOGIN });
    }
  };
  return (
    <Modal
      blockScrollOnMount={true}
      size="lg"
      isOpen={isOpen}
      onClose={handleClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Login</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={4} p="1rem" backgroundColor="whiteAlpha.900">
              <FormControl mb="3">
                <InputGroup>
                  <InputLeftElement
                    top={1.5}
                    pointerEvents="none"
                    children={<CFaUserAlt h="full" color="gray.500" />}
                  />
                  <Input
                    fontSize={15}
                    py="6"
                    isInvalid={errors.email ? true : false}
                    errorBorderColor="red.500"
                    placeholder="Email address"
                    _placeholder={{ color: "var(--chakra-colors-gray-500)" }}
                    autoComplete="off"
                    {...register("email", {
                      required: "Email is required",
                      pattern: {
                        value:
                          /^([0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*@([0-9a-zA-Z][-\w]*[0-9a-zA-Z]\.)+[a-zA-Z]{2,3})$/,
                        message: "Email is invalid",
                      },
                    })}
                  />
                </InputGroup>

                {errors.email && (
                  <div className="error">{errors.email.message}</div>
                )}
              </FormControl>
              <FormControl mb="5">
                <InputGroup>
                  <InputLeftElement
                    top={1.5}
                    pointerEvents="none"
                    color="gray.300"
                    children={<CFaLock color="gray.500" />}
                  />
                  <Input
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    _placeholder={{ color: "var(--chakra-colors-gray-500)" }}
                    fontSize={15}
                    py="6"
                    isInvalid={errors.password ? true : false}
                    errorBorderColor="red.500"
                    autoComplete="off"
                    {...register("password", {
                      required: "Password is required",
                      pattern: {
                        value: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/,
                        message:
                          "Must be at least one number, one lowercase, one uppercase letter and one special character ",
                      },
                      minLength: {
                        value: 8,
                        message: "Password should be at least 8 characters",
                      },
                    })}
                  />

                  <InputRightElement top={1.5} width="4.5rem">
                    <Button h="1.75rem" size="sm" onClick={handleShowClick}>
                      {showPassword ? (
                        <ViewIcon fontSize={15} color="gray.500" />
                      ) : (
                        <ViewOffIcon fontSize={15} color="gray.500" />
                      )}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                {errors.password && (
                  <div className="error">{errors.password.message}</div>
                )}
              </FormControl>
              <Text textAlign="right" fontSize={13}>
                <Link
                  as={Button}
                  onClick={() => {
                    dispatch({ type: actionTypes.HIDE_LOGIN });
                    dispatch({ type: actionTypes.SHOW_FORGET_PASSWORD });
                  }}
                >
                  Forgot password?
                </Link>
              </Text>
              <Button
                borderRadius={0}
                type="submit"
                variant="solid"
                isLoading={isSubmitting}
                width="full"
                fontSize={15}
                py="6"
                backgroundColor="primary"
                color="whiteAlpha.800"
                rounded="md"
                _hover={{
                  color: "var(--chakra-colors-gray-500)",
                  backgroundColor: "#ddd",
                }}
              >
                Login
              </Button>
              <Text fontSize="1rem" my="10">
                Don't have account ?{" "}
                <Link
                  fontWeight="500"
                  color="primary"
                  onClick={handleShowSignUp}
                >
                  Sign up
                </Link>
              </Text>
            </Stack>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default Login;
