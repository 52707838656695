import CourseLayout from "../../components/CourseLayout";
import React, { useEffect } from "react";
import * as actionTypes from "../../redux/actionTypes";
import { useDispatch } from "react-redux";
import Layout from "../../components/Layout";

const CMAT: React.FC = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: actionTypes.SET_COURSE, payload: "cmat" });
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <Layout>
      <CourseLayout
        colleges={[]}
        title="cmat"
        type="cmat"
        description="Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum libero
quaerat perferendis totam animi odio fuga! Fugiat perferendis eos
dolorem! Quam deleniti tempore commodi consequatur sapiente! Corporis
nisi iusto odit, voluptatum dolor ipsum aliquam quaerat. Asperiores
nesciunt eaque aliquam porro?"
      >
        <div className="divider-md"></div>
      </CourseLayout>
    </Layout>
  );
};

export default CMAT;
