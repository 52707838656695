import cogoToast from "cogo-toast";
import Api from "../../services/api";
import * as actionTypes from "../actionTypes";
export const getMCQ =
  (course: string | any, section: string | any) => async (dispatch: any) => {
    dispatch({ type: actionTypes.GET_MCQ_REQUEST });
    try {
      const api = new Api(false);
      const response = await api.get(
        `/api/MCQ/?section=${section}&course=${course}`,
        {}
      );
      let mcqs: any = [];
      const math = response.data?.filter(
        (mcq: any, index: number) => mcq.subject?.title === "Math"
      );
      const english = response.data?.filter(
        (mcq: any, index: number) => mcq.subject?.title === "English"
      );
      const physics = response.data?.filter(
        (mcq: any, index: number) => mcq.subject?.title === "Physics"
      );
      const chemistry = response.data?.filter(
        (mcq: any, index: number) => mcq.subject?.title === "Chemistry"
      );
      const verbal_ability = response.data?.filter(
        (mcq: any, index: number) => mcq.subject?.title === "Verbal Ability"
      );
      const quantitative_ability = response.data?.filter(
        (mcq: any, index: number) =>
          mcq.subject?.title === "Quantitative Ability"
      );
      const logical_reasoning = response.data?.filter(
        (mcq: any, index: number) => mcq.subject?.title === "Logical Reasoning"
      );
      const general_knowledge = response.data?.filter(
        (mcq: any, index: number) => mcq.subject?.title === "General Knowledge"
      );
      const general_it = response.data?.filter(
        (mcq: any, index: number) => mcq.subject?.title === "General IT"
      );
      if (course === "CSIT") {
        mcqs = [...math, ...english, ...physics, ...chemistry];
      } else if (course === "CMAT") {
        mcqs = [
          ...verbal_ability,
          ...quantitative_ability,
          ...logical_reasoning,
          ...general_knowledge,
        ];
      } else if (course === "BCA") {
        mcqs = [
          ...verbal_ability,
          ...quantitative_ability,
          ...general_knowledge,
        ];
      } else if (course === "BIT") {
        mcqs = [
          ...verbal_ability,
          ...quantitative_ability,
          ...general_knowledge,
          ...general_it,
        ];
      }

      const formattedMCQ = mcqs.map((mcq: any, index: number) => {
        return {
          id: mcq.id,
          index: index,
          question: mcq.title,
          options: {
            A: mcq["A"],
            B: mcq["B"],
            C: mcq["C"],
            D: mcq["D"],
          },
          subject: mcq?.subject.title,
          is_choosed: false,
          choosed_option: "",
          is_correct: false,
        };
      });
      dispatch({ type: actionTypes.GET_MCQ_SUCCESS, payload: formattedMCQ });
    } catch (e: any) {
      dispatch({ type: actionTypes.GET_MCQ_FAILED });
    }
  };

export const getSection = (course: string) => async (dispatch: any) => {
  dispatch({ type: actionTypes.GET_SECTION_REQUEST, payload: [] });
  try {
    const api = new Api(false);
    const response = await api.get(`/api/section/`, {});
    const course_type = `${course}`.toUpperCase();
    const sections = response.data?.filter(
      (section: any) => section.course.title === course_type
    );

    dispatch({ type: actionTypes.GET_SECTION_SUCCESS, payload: sections });
  } catch (e) {
    dispatch({ type: actionTypes.GET_MCQ_FAILED });
  }
};

export const getSectionById = async (id: string) => {
  try {
    const api = new Api(false);
    const response = await api.get(`/api/section/${id}`, {});
    return response;
  } catch (e: any) {
    return e.response;
  }
};

export const scoreCheck = (data: any) => async (dispatch: any) => {
  dispatch({ type: actionTypes.UPDATE_CORRECT_REQUEST });
  try {
    const api = new Api(true);
    const res = await api.post(`/score/check/`, data, {
      "Content-Type": "multipart/form-data",
    });
    dispatch({
      type: actionTypes.UPDATE_CORRECT_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({ type: actionTypes.SUBMIT_FALSE });
    dispatch({ type: actionTypes.UPDATE_CORRECT_FAILED });
    return cogoToast.error("Something went wrong. Please submit again.");
  }
};
export const submitMockTest = (data: any) => async (dispatch: any) => {
  dispatch({ type: actionTypes.SET_SUBMITTING, paylaod: true });
  try {
    const api = new Api(true);
    const res = await api.post(`/score/mocktest/`, data, {
      "Content-Type": "multipart/form-data",
    });
    dispatch({ type: actionTypes.SUBMIT_TRUE });
    dispatch({ type: actionTypes.SET_SUBMITTING, paylaod: false });
    dispatch({ type: actionTypes.SHOW_SUCCESS });
    return res;
  } catch (e) {
    dispatch({ type: actionTypes.SUBMIT_FALSE });
    dispatch({ type: actionTypes.SET_SUBMITTING, paylaod: false });
    return cogoToast.error("Something went wrong. Please submit again.");
  }
};

export const updateAnswerSheet = (option: string, id: number | string) => {
  return {
    type: actionTypes.UPDATE_ANSWER_SHEET,
    payload: { option, id },
  };
};

export const mocktestResultCheck = async (beid: string | number) => {
  try {
    const api = new Api(false);
    const res = await api.get(`/score/mocktestresult/?beid=${beid}`, {});
    return res;
  } catch (e: any) {
    cogoToast.error("Failed to get your result. Please check again later.");
    return e.response;
  }
};

export const fetchPhysicalMockTestResult = async () => {
  try {
    const api = new Api(false);
    const res = await api.get(`/score/physicalmocktestresult/`, {});
    return res;
  } catch (e: any) {
    cogoToast.error("Failed to load resutl. Please check it later.");
    return e.response;
  }
};

export const fetchSinglePhysicalMockTestResult = async (
  id: string | number
) => {
  try {
    const api = new Api(false);
    const res = await api.get(`/score/physicalmocktestresult/${id}/`, {});
    return res;
  } catch (e: any) {
    cogoToast.error("Failed to load resutl. Please check it later.");
    return e.response;
  }
};
