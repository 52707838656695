import Api from "../../services/api";
import * as actionTypes from "../actionTypes";
export const getNotice = () => async (dispatch: any) => {
  try {
    const api = new Api(false);
    const res = await api.get(`/api/notices/`, {});
    console.log(res);
    dispatch({
      type: actionTypes.SET_NOTICE,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: actionTypes.SET_NOTICE,
      payload: [],
    });
  }
};
export const getSingleNotice =
  (id: number | string | any) => async (dispatch: any) => {
    dispatch({ type: actionTypes.GET_SINGLE_NOTICE_REQUEST });
    try {
      const api = new Api(false);
      const res = await api.get(`/api/notices/${id}`, {});
      dispatch({
        type: actionTypes.GET_SINGLE_NOTICE_SUCCESS,
        payload: res.data,
      });
    } catch (e) {
      dispatch({
        type: actionTypes.GET_SINGLE_NOTICE_FAILED,
        payload: {},
      });
    }
  };
