import Layout from "../../components/Layout";
import Navbar from "../../components/Navbar";
import NoticeLayout from "../../components/NoticeLayout";
import React, { useEffect } from "react";
import { BsPlay } from "react-icons/bs";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getNotice } from "../../redux/actions/notice";
import { noticeStateType } from "../../redux/reducers/notice";
import { State } from "../../redux/rootReducers";
import { Box, Center, Text } from "@chakra-ui/layout";
import { Image } from "@chakra-ui/image";
import notice from "../../assets/images/notice.svg";
const Notice: React.FC = () => {
  const dispatch = useDispatch();
  const { notices } = useSelector<State, noticeStateType>(
    (state) => state.notice
  );
  useEffect(() => {
    dispatch(getNotice());
    window.scrollTo({ behavior: "smooth", top: 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Layout>
      <Navbar active="active" />
      <div className="divider-md"></div>
      <NoticeLayout>
        <div className="main-container">
          <h1 className="section-title">Notices</h1>
          <div className="notice-container">
            <div className="notice-list">
              {notices
                ?.filter((notice) => notice.display === true)
                .map((notice, i) => (
                  <Link
                    key={i}
                    to={`/notice/${notice.id}`}
                    className="notice-item"
                  >
                    <div className="icon-container">
                      <BsPlay size={20} color="#0e62ac" />
                    </div>
                    <span className="notice-title">{notice.title}</span>
                  </Link>
                ))}
              {notices?.length === 0 && (
                <Box>
                  <Center>
                    <Image src={notice} boxSize={["full", "18rem", "20rem"]} />
                  </Center>
                  <Text textAlign="center" fontSize="1rem">
                    No Notice Posted Yet.
                  </Text>
                  <div className="divider"></div>
                </Box>
              )}
            </div>
            <div className="right-item"></div>
          </div>
        </div>
      </NoticeLayout>
    </Layout>
  );
};

export default Notice;
