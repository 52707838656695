import cogoToast from "cogo-toast";
import Api from "../../services/api";
import * as actionTypes from "../actionTypes";
export const getToken = (): string | boolean | any => {
  const token = localStorage.getItem("token");
  if (token) {
    return token;
  } else {
    return false;
  }
};
export const login = (data: any) => async (dispatch: any) => {
  try {
    const api = new Api(false);
    const response = await api.post("/user/login/", data, {
      "Content-Type": "application/json",
      Accept: "application/json",
    });
    localStorage.setItem("token", response.data.token);
    cogoToast.success("Logged in Success.!", { position: "top-center" });
    dispatch({ type: actionTypes.SET_LOGGED_OUT, payload: false });
    return response;
  } catch (e: any) {
    cogoToast.error(e.response?.data?.message[0], { position: "top-center" });
    localStorage.removeItem("token");
    return e.response;
  }
};

export const getUserProfile = () => async (dispatch: any) => {
  dispatch({ type: actionTypes.GET_PROFILE_REQUEST });
  try {
    const api = new Api(true);
    const response = await api.get("/user/profile/", {
      "Content-Type": "application/json",
      Accept: "application/json",
    });
    dispatch({
      type: actionTypes.GET_PROFILE_SUCCESS,
      payload: response.data["User data"],
    });
    return response;
  } catch (e: any) {
    dispatch({
      type: actionTypes.GET_PROFILE_FAILED,
    });
    return e.response;
  }
};
export const logout = () => async (dispatch: any) => {
  localStorage.removeItem("token");
  dispatch({ type: actionTypes.LOGOUT });
};
export const signUp = (data: any) => async (dispatch: any) => {
  dispatch({ type: actionTypes.REGISTER_REQUEST });
  try {
    const api = new Api(false);
    const response = await api.post("/user/register/", data, {
      "Content-Type": "multipart/form-data",
    });
    cogoToast.success("Registration Successful..!", { position: "top-center" });
    return response;
  } catch (e: any) {
    // for (let error in e.response.data) {
    //   cogoToast.error(e.response.data[error][0], { position: "top-center" });
    // }
    if (e.response?.status === 400 || e.response?.status === 401) {
      cogoToast.error("Email already exists.", {
        position: "top-center",
      });
    } else {
      cogoToast.error("Internal Server Error.", {
        position: "top-center",
      });
    }

    return e.response;
  }
};

export const forgetPassword = (data: any) => async (dispatch: any) => {
  try {
    const api = new Api(false);
    const response = await api.patch("/user/reset/", data);
    cogoToast.success("Success.! Your password is reset.", {
      position: "top-center",
    });
    return response;
  } catch (e: any) {
    cogoToast.error("Failed.", {
      position: "top-center",
    });
    return e.response;
  }
};

export const sendOtpForReset = (data: any) => async (dispatch: any) => {
  try {
    const api = new Api(false);
    const response = await api.post("/user/reset/", data);
    cogoToast.success("Success, OTP code send to your email.");
    return response;
  } catch (e: any) {
    cogoToast.error("Failed.", { position: "top-center" });
    return e.response;
  }
};

export const verifyUser = (data: any) => async (dispatch: any) => {
  try {
    const api = new Api(true);
    const response = await api.patch("/user/verify/", data);
    cogoToast.success("Success.! Verification", { position: "top-center" });
    return response;
  } catch (e: any) {
    return e.response;
  }
};

export const sendOtpForVerify = (data: any) => async (dispatch: any) => {
  try {
    const api = new Api();
    const response = await api.post("/user/verify/", data);
    cogoToast.success("Success, OTP code send to your email", {
      position: "top-center",
    });
    return response;
  } catch (e: any) {
    cogoToast.error("Invalid OTP Code.", {
      position: "top-center",
    });
    return e.response;
  }
};
