import React from "react";
import Navbar from "../Navbar";
import Layout from "./styled";
import { motion } from "framer-motion";
import { Breadcrumb } from "@chakra-ui/breadcrumb";
import { BreadcrumbItem } from "@chakra-ui/breadcrumb";
import { BreadcrumbLink } from "@chakra-ui/breadcrumb";
import { Link, useNavigate } from "react-router-dom";

import {
  IoClipboardOutline,
  IoHomeOutline,
  IoReaderOutline,
  IoRibbonOutline,
  IoSchoolOutline,
} from "react-icons/io5";
import { college } from "../../@types/college";
import { Spinner } from "@chakra-ui/spinner";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import tu_banner from "../../assets/images/tu_banner.jpg";
import "swiper/swiper-bundle.css";
import CollegeCard from "../CollegeCard";
import { Button } from "@chakra-ui/button";
import { Center } from "@chakra-ui/layout";
import Footer from "../Footer";
SwiperCore.use([Autoplay, Pagination]);

type LayoutProps = {
  title: string;
  description: string;
  colleges: college[];
  type: string;
};

const CourseLayout: React.FC<LayoutProps> = ({
  title,
  children,
  type,
  description,
  colleges,
}) => {
  const navigate = useNavigate();
  return (
    <Layout>
      <Navbar active="active" />
      <div className="course-container">
        <div className="course-header">
          <img src={tu_banner} alt="Tribhuvan University" />
          <div className="header-content">
            <div className="header-content-wrapper">
              <Breadcrumb color="#eee">
                <BreadcrumbItem color="#eee" fontSize="1.5rem">
                  <BreadcrumbLink
                    as={Link}
                    to="/"
                    color="#eee"
                    fontSize="1.1rem"
                    textTransform="uppercase"
                  >
                    Home
                  </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <BreadcrumbLink
                    as={Link}
                    to={`/${type}`}
                    color="#eee"
                    fontSize="1.1rem"
                    textTransform="uppercase"
                  >
                    {type}
                  </BreadcrumbLink>
                </BreadcrumbItem>
              </Breadcrumb>

              <motion.h1
                className="course-title"
                initial={{ y: -20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{
                  duration: 0.5,
                  delay: 0.2,
                }}
              >
                {title}
              </motion.h1>
            </div>
          </div>
        </div>
        <div className="course-body">
          <div className="course-body-wrapper">
            <div className="course-details">
              <div className="top-grid-container">
                <div className="item">
                  <IoSchoolOutline color="#0e62ac" size="4rem" />
                  <span>55+ Colleges</span>
                </div>
                <div className="item">
                  <IoReaderOutline color="#0e62ac" size="4rem" />
                  <span>10+ Mock Test Paper</span>
                </div>
                <div className="item">
                  <IoRibbonOutline color="#0e62ac" size="4rem" />
                  <span>Special Schoolarship upto 50%</span>
                </div>
              </div>
              <div className="bottom-buttons-container">
                <Center>
                  <Button
                    py={["17px", "20px"]}
                    px={["15px", "20px", "25px"]}
                    borderRadius="6px"
                    bg="#fff"
                    color="#0e62ac"
                    _active={{
                      bg: "#eee",
                    }}
                    fontWeight="600"
                    fontSize={["15px", "18px", "20px"]}
                    fontFamily="inherit"
                    border="1.5px solid #0e62ac"
                    outline="none"
                    d="flex"
                    alignItems="center"
                    gridGap="0.7rem"
                    cursor="pointer"
                    onClick={() => navigate(`/test/${type}`)}
                  >
                    <IoClipboardOutline color="#0e62ac" />
                    Online test
                  </Button>
                </Center>
              </div>
            </div>
          </div>
        </div>
        {colleges?.length > 0 && (
          <section className="section">
            <h1 className="section-title">Top 3 CSIT Colleges</h1>
            {!colleges && (
              <p className="loading-text">
                <Spinner
                  thickness="3px"
                  speed="0.65s"
                  emptyColor="#0e62ac33"
                  color="#0e62ac"
                  h="2rem"
                  w="2rem"
                  mr="8px"
                />
                <span>Fetching data</span>
              </p>
            )}
            <div className="divider-md"></div>

            <Swiper
              pagination={{ clickable: true, type: "bullets" }}
              speed={1000}
              loop={true}
              spaceBetween={15}
              slidesPerView={1}
              autoplay={{ delay: 2500, disableOnInteraction: false }}
              breakpoints={{
                657: {
                  slidesPerView: 2,
                  spaceBetween: 15,
                },
                947: {
                  slidesPerView: 3,
                  spaceBetween: 15,
                },
              }}
            >
              {colleges.map((college: college, index: number) => (
                <SwiperSlide key={index}>
                  <CollegeCard
                    college_name={college.college_name}
                    type={college.type}
                    location={college.location}
                    logo={college.logo}
                    website={college.website}
                  />
                </SwiperSlide>
              ))}
            </Swiper>

            {/* <div className="button-container">
            <Button
              color="#0e62ac"
              p="8px 12px"
              fontSize="1.5rem"
              border="1.2px solid #0e62ac"
              bg="transparent"
              fontFamily="inherit"
              borderRadius="0.5rem"
              cursor="pointer"
              transition="all .2s linear"
              fontWeight={500}
              _hover={{
                bg: "#0e62ac",
                color: "#eee",
              }}
            >
              View all college list
            </Button>
          </div> */}
          </section>
        )}
        <div className="divider-md"></div>
      </div>
      <Footer />
    </Layout>
  );
};

export default CourseLayout;
