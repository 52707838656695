import { Image } from "@chakra-ui/image";
import { Center, Heading, Text, Box, Flex } from "@chakra-ui/layout";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getSingleNotice } from "../../redux/actions/notice";
import { noticeStateType } from "../../redux/reducers/notice";
import { State } from "../../redux/rootReducers";
import Layout from "../Layout";
import Navbar from "../Navbar";
import ReactHtmlParser from "react-html-parser";
import NoticeLayout from "../NoticeLayout";
import { FiClock } from "react-icons/fi";
import moment from "moment";
const NoticeDetails = () => {
  const { id }: any = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { singleNotice, error } = useSelector<State, noticeStateType>(
    (state) => state.notice
  );
  useEffect(() => {
    dispatch(getSingleNotice(id));
    window.scrollTo({ behavior: "smooth", top: 0 });
  }, []);
  useEffect(() => {
    if (error) {
      navigate("/404");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  console.log(singleNotice);
  return (
    <Layout>
      <Navbar active="active" />
      <div className="divider"></div>
      <NoticeLayout>
        <div className="main-container">
          {singleNotice && (
            <div className="single-notice-container">
              <Heading
                as="h1"
                fontSize="2rem"
                fontWeight="600"
                mt={["25px", "25px", "35px"]}
                mb={["10px", "15px"]}
              >
                {singleNotice?.title}
              </Heading>
              <Box mt="8px" mb="20px">
                {singleNotice?.date_published && (
                  <Flex gridGap="7px" align="center">
                    <FiClock fontSize="1rem" />
                    <span style={{ fontSize: "1rem" }}>
                      {moment(singleNotice?.date_published).format("ll")}
                    </span>
                  </Flex>
                )}
              </Box>
              {ReactHtmlParser(singleNotice?.description)}
            </div>
          )}
        </div>

        <div className="divider"></div>
      </NoticeLayout>
    </Layout>
  );
};

export default NoticeDetails;
