import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Button, Divider, Text, Flex } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../redux/rootReducers";
import { userStateType } from "../../redux/reducers/user";
import * as actionTypes from "../../redux/actionTypes";
import { useNavigate } from "react-router-dom";
import { useTimer } from "react-timer-hook";
import moment from "moment";
type Props = {
  title?: string;
  course?: string;
  start_date?: string | Date | any;
  end_date?: string | Date | any;
  id: number;
};
const ScheduledMcqCard: React.FC<Props> = ({
  title,
  course,
  start_date,
  end_date,
  id,
}) => {
  const navigate = useNavigate();
  const userState = useSelector<State, userStateType>((state) => state.user);
  const { isVerified } = userState;
  const [started, setStarted] = useState<boolean>(false);
  const [ended, setEnded] = useState<boolean>(false);

  const dispatch = useDispatch();
  const time = new Date();
  if (!started) {
    time.setTime(new Date(start_date).getTime());
  }
  const { seconds, minutes, days, hours, restart, isRunning } = useTimer({
    autoStart: true,
    expiryTimestamp: time,
    onExpire: () => {
      setStarted(false);
      setEnded(true);
    },
  });
  const handleSchedule = () => {
    if (
      new Date().getTime() >= new Date(start_date).getTime() &&
      new Date().getTime() <= new Date(end_date).getTime()
    ) {
      setStarted(true);
      restart(new Date(end_date), true);
      setEnded(false);
    }
  };
  setInterval(handleSchedule, 1000);
  const handleStart = () => {
    if (isVerified) {
      navigate(
        `/mocktest/${course}/${title}?id=${id}&st=${new Date(
          start_date
        ).getTime()}&et=${new Date(end_date).getTime()}`
      );
    } else {
      dispatch({ type: actionTypes.SHOW_VERIFY_ALERT });
    }
  };
  useEffect(() => {
    if (start_date) {
      restart(new Date(start_date), true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start_date]);
  console.log(title, started);
  return (
    <Box>
      <div className="top-container">
        <h1 className={started ? "mcq-title live" : "mcq-title"}>
          {title ? `${title}`.split("-")[1] : "Model Set"}
        </h1>
        <div className="tags-container">
          <span className="tag">Practice set</span>
          <span className="tag">TU</span>
          <span className="tag">IOST</span>
          <span className="tag">{title ? title : ""}</span>
        </div>
      </div>
      <div>
        {!started && start_date && (
          <Text fontSize={["13px", "14px", "15px"]} m="2px" p="0">
            <span style={{ fontWeight: "bold" }}>Start :</span>{" "}
            {moment(start_date).format("llll")}
          </Text>
        )}
        {!started && end_date && (
          <Text fontSize={["13px", "14px", "15px"]} m="2px" p="0">
            <span style={{ fontWeight: "bold" }}>End :</span>{" "}
            {moment(end_date).format("llll")}
          </Text>
        )}
        <Divider h="10px" />
        {isRunning && (
          <Text fontSize="14px" mb="8px">
            <span style={{ fontWeight: "bold" }}>
              {started ? "Time Remaining" : "Starting in"}:
            </span>
            <Text as="span" fontSize="17px" fontWeight="bold">
              {" "}
              {days > 0 && <>{`${days}`.padStart(2, "0")}:</>}
              {`${hours}`.padStart(2, "0")}:{`${minutes}`.padStart(2, "0")}:
              {`${seconds}`.padStart(2, "0")}
            </Text>
          </Text>
        )}
        <Flex gridGap="8px">
          <Text fontSize="14px" fontWeight="500">
            {" "}
            Status :{" "}
          </Text>
          {!started && !ended && (
            <Text fontWeight="500" color="#aaaaaa" fontSize="15px">
              Not Started
            </Text>
          )}
          {started && (
            <Text fontWeight="500" color="green.800" fontSize="15px">
              On going
            </Text>
          )}
          {ended && (
            <Text fontWeight="500" color="red.800" fontSize="15px">
              Time Over
            </Text>
          )}
        </Flex>
      </div>
      <div className="bottom-container">
        {started && isRunning && (
          <Button
            fontSize="16px"
            textTransform="uppercase"
            bg="#0e62ac"
            py={["17px", "18px"]}
            color="#eee"
            fontWeight={500}
            border="none"
            outline="none"
            w="full"
            fontFamily="inherit"
            _hover={{
              color: "var(--chakra-colors-white)",
            }}
            cursor="pointer"
            onClick={() => handleStart()}
          >
            Start now
          </Button>
        )}
        {/* {ended && (
          <Button
            fontSize="16px"
            textTransform="uppercase"
            bg="#0e62ac"
            py={["17px", "18px"]}
            color="#eee"
            fontWeight={500}
            border="none"
            outline="none"
            w="full"
            fontFamily="inherit"
            _hover={{
              color: "var(--chakra-colors-white)",
            }}
            cursor="pointer"
            disabled
          >
            Ended
          </Button>
        )} */}
      </div>
    </Box>
  );
};
const Box = styled.div`
  border: 2px solid #ddd;
  background: #e9e9e9;
  user-select: none;
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  height: fit-content;
  flex-direction: column;
  gap: 2rem;
  justify-content: space-between;
  max-width: 100%;
  min-height: 250px;
  .top-container {
    .mcq-title {
      font-size: 1.4rem;
      font-weight: 500;
      color: #0e62ac;
      transition: all 0.3s linear;
      &.live {
        width: fit-content;
        color: #ff5722;
        position: relative;
        &::before,
        &::after {
          content: "";
          position: absolute;
          bottom: -3px;
          height: 2.5px;
          background-color: #ff5722;
          animation: animateLine 1.5s ease-out infinite;
        }
        @keyframes animateLine {
          0% {
            width: 0%;
            left: 0;
            right: 100%;
          }
          50% {
            width: 100%;
            left: 0;
            right: 0;
          }
          100% {
            left: 100%;
            width: 0%;
            right: 0;
          }
        }
      }
    }
    .tags-container {
      display: flex;
      gap: 5px;
      flex-wrap: wrap;
      margin-top: 25px;
      .tag {
        cursor: text;
        padding: 3px 8px;
        border-radius: 8px;
        font-size: 15px;
        font-weight: 500;
        background: #0e62ac44;
        color: #0e62ac;
      }
    }
  }
  @media only screen and (max-width: 567px) {
    max-width: 100%;
  }
  .bottom-container {
    display: flex;
    gap: 10px;
  }
`;
export default ScheduledMcqCard;
