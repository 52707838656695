import * as actionTypes from "../actionTypes";
export type layoutStateType = {
  showRegister: boolean;
  showLogin: boolean;
  showForgetPassword: boolean;
  showVerification: boolean;
  verifyAlert: boolean;
};
const initState: layoutStateType = {
  showRegister: false,
  showLogin: false,
  showForgetPassword: false,
  showVerification: false,
  verifyAlert: true,
};
const layoutReducer = (state: layoutStateType = initState, action: any) => {
  const { type } = action;
  switch (type) {
    case actionTypes.SHOW_LOGIN:
      return { ...state, showLogin: true };
    case actionTypes.HIDE_LOGIN:
      return { ...state, showLogin: false };
    case actionTypes.SHOW_REGISTER:
      return { ...state, showRegister: true };
    case actionTypes.HIDE_REGISTER:
      return { ...state, showRegister: false };
    case actionTypes.SHOW_VERIFICATION:
      return { ...state, showVerification: true };
    case actionTypes.HIDE_VERIFICATION:
      return { ...state, showVerification: false };
    case actionTypes.SHOW_FORGET_PASSWORD:
      return { ...state, showForgetPassword: true };
    case actionTypes.HIDE_FORGET_PASSWORD:
      return { ...state, showForgetPassword: false };
    case actionTypes.SHOW_VERIFY_ALERT:
      return { ...state, verifyAlert: true };
    case actionTypes.HIDE_VERIFY_ALERT:
      return { ...state, verifyAlert: false };
    default:
      return state;
  }
};
export default layoutReducer;
