import * as actionTypes from "../actionTypes";
export type galleryStateType = {
  galleries: any[];
};
const initState: galleryStateType = {
  galleries: [],
};
const galleryReducer = (state: galleryStateType = initState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET_GALLERY:
      return { ...state, galleries: payload };
    default:
      return state;
  }
};
export default galleryReducer;
