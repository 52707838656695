import styled from "@emotion/styled";
const HeroSliderContainer = styled.div`
  height: fit-content;
  padding: 0 3rem;
  h1 {
    color: $light;
    font-size: 2.5rem;
    font-weight: 600;
    opacity: 0.8;
    @media only screen and (max-width: 567px) {
      font-size: 1.8rem;
    }
  }
  p {
    margin-top: 0.6rem;
    font-size: 1.2rem;
    font-weight: 500;
    @media only screen and (max-width: 567px) {
      font-size: 1.1rem;
    }
  }
  @media only screen and (max-width: 750px) {
    padding: 0 1.8rem;
  }
  @media only screen and (max-width: 567px) {
    padding: 0 1.5rem;
  }
`;
export default HeroSliderContainer;
