import { combineReducers } from "redux";
import blogReducer, { blogStateType } from "./reducers/blog";
import courseReducer, { courseStateType } from "./reducers/course";
import galleryReducer, { galleryStateType } from "./reducers/gallery";
import layoutReducer, { layoutStateType } from "./reducers/LayoutReducer";
import noticeReducer, { noticeStateType } from "./reducers/notice";
import quizReducer, { quizStateType } from "./reducers/quiz";
import userReducer, { userStateType } from "./reducers/user";
export type State = {
  layout: layoutStateType;
  quiz: quizStateType;
  user: userStateType;
  course: courseStateType;
  gallery: galleryStateType;
  notice: noticeStateType;
  blog: blogStateType;
};
const rootReducer = combineReducers({
  layout: layoutReducer,
  quiz: quizReducer,
  user: userReducer,
  course: courseReducer,
  gallery: galleryReducer,
  notice: noticeReducer,
  blog: blogReducer,
});
export default rootReducer;
