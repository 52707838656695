import {
  Box,
  Flex,
  Link,
  SimpleGrid,
  Text,
  HStack,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { FaFacebook } from "react-icons/fa";
import Logo from "../Logo";
import { Link as ReactLink } from "react-router-dom";
import styled from "@emotion/styled";
const Footer: React.FC = () => {
  return (
    <FooterSection>
      <Box bg="#dedede" className="footer-container">
        <SimpleGrid
          columns={[1, 1, 2, 3]}
          justifyContent="between"
          gap={[8, 10, 12, 12]}
          alignItems={["flex-start", "center"]}
        >
          <Box maxW="500">
            <Logo height="2.9rem" />
            <Box mt="6">
              <Text
                fontSize={["0.8rem", "1.1rem"]}
                color="blackAlpha.700"
                fontWeight="400"
              >
                <Link
                  _hover={{ textDecoration: "none", color: "primary" }}
                  transition="all .1s linear"
                  href="tel:9844762048"
                >
                  9844762048
                </Link>
                ,{" "}
                <Link
                  _hover={{ textDecoration: "none", color: "primary" }}
                  transition="all .1s linear"
                  href="tel:9863622966"
                >
                  9863622966
                </Link>
              </Text>
              <Text
                fontSize={["0.8rem", "1.1rem"]}
                color="blackAlpha.700"
                fontWeight="400"
              >
                <Link
                  _hover={{ textDecoration: "none", color: "primary" }}
                  transition="all .1s linear"
                  href="mailto:info@binaryentrance.com"
                >
                  info@binaryentrance.com
                </Link>
              </Text>
              <Text
                fontSize={["0.8rem", "1.1rem"]}
                color="blackAlpha.700"
                fontWeight="400"
              >
                Putalisadak, Kathmandu
              </Text>
            </Box>
          </Box>
          <Flex justifyContent="space-between" gap={[10, 11, 12]}>
            <Text color="blackAlpha.700" fontSize="1rem">
              <Link
                textDecoration="none"
                transition="all .1s linear"
                _hover={{ color: "primary" }}
                href="/"
              >
                Terms and Conditions
              </Link>
            </Text>
            <Text color="blackAlpha.700" fontSize="1rem">
              <Link
                textDecoration="none"
                transition="all .1s linear"
                as={ReactLink}
                _hover={{ color: "primary" }}
                to="/privacy-policy"
              >
                Privacy Policy
              </Link>
            </Text>
          </Flex>
          <VStack flexWrap="wrap">
            <Text fontSize="1rem">Social Links</Text>
            <HStack spacing="8" justifyContent="flex-start">
              <Link
                target="_blank"
                href="https://www.facebook.com/binaryentrance"
              >
                <FaFacebook size={28} color="var(--chakra-colors-primary)" />
              </Link>
              {/* <Link target="_blank" href="">
                <SiLinkedin size={25} color="var(--chakra-colors-primary)" />
              </Link>
              <Link target="_blank" href="">
                <AiFillInstagram
                  size={34}
                  color="var(--chakra-colors-primary)"
                />
              </Link> */}
            </HStack>
          </VStack>
        </SimpleGrid>
        <Text
          color="primary"
          fontSize={["0.8rem", "1.1rem"]}
          align="center"
          mt="30px"
        >
          &copy; {new Date().getFullYear()} Binary Entrance.
        </Text>
      </Box>
    </FooterSection>
  );
};

export default Footer;

const FooterSection = styled.div`
  max-width: 1600px;
  margin: 0 auto;
  background: "#dddddd";
  .footer-container {
    display: grid;
    padding: 1.5rem 3rem;

    @media only screen and (max-width: 750px) {
      padding: 1.5rem 1.8rem;
    }
    @media only screen and (max-width: 567px) {
      padding: 1.3rem;
    }
  }
`;
