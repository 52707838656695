import { Button } from "@chakra-ui/button";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { layoutStateType } from "../../redux/reducers/LayoutReducer";
import { userStateType } from "../../redux/reducers/user";
import { State } from "../../redux/rootReducers";
import ForgetPassword from "../ForgetPassword";
import Login from "../Login";
import { FaExclamationCircle } from "react-icons/fa";
import SignUp from "../SignUp";
import Verification from "../Verification";
import * as actionTypes from "../../redux/actionTypes";
import { HStack, Text } from "@chakra-ui/layout";
const Layout: React.FC = ({ children }) => {
  const layout = useSelector<State, layoutStateType>((state) => state.layout);
  const user = useSelector<State, userStateType>((state) => state.user);
  const { isAuthenticated, isVerified } = user;
  const dispatch = useDispatch();
  const {
    showLogin,
    showRegister,
    showForgetPassword,
    showVerification,
    verifyAlert,
  } = layout;
  const handleVerify = () => {
    dispatch({ type: actionTypes.HIDE_VERIFY_ALERT });
    dispatch({ type: actionTypes.SHOW_VERIFICATION });
  };
  return (
    <React.Fragment>
      <>
        <Login isOpen={showLogin} />
        <SignUp isOpen={showRegister} />
        <ForgetPassword isOpen={showForgetPassword} />
        <Verification isOpen={showVerification} />
        <Modal
          isOpen={isAuthenticated && !isVerified && verifyAlert}
          onClose={() => {
            dispatch({ type: actionTypes.HIDE_VERIFY_ALERT });
          }}
          size="lg"
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <HStack>
                <FaExclamationCircle
                  color="var(--chakra-colors-primary)"
                  size={16}
                />
                <Text fontSize={16} color="#000000" fontWeight="500">
                  Verify your account !
                </Text>
              </HStack>
            </ModalHeader>
            <ModalBody fontSize={14} mb="5">
              Your account is not verified yet. To play online quiz or attend in
              any online mock test in our system, you must be verified.
            </ModalBody>
            <ModalFooter>
              <Button
                fontSize="15px"
                bg="#0e62ac"
                py={["8px", "10px", "15px"]}
                color="#eee"
                fontWeight={600}
                border="none"
                outline="none"
                px={["10px", "25px"]}
                fontFamily="inherit"
                _hover={{
                  color: "var(--chakra-colors-white)",
                }}
                cursor="pointer"
                onClick={handleVerify}
              >
                Verify
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
      {children}
    </React.Fragment>
  );
};

export default Layout;
