import { Button } from "@chakra-ui/button";
import { FormControl } from "@chakra-ui/form-control";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import {
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/input";
import { HStack, Stack, Text } from "@chakra-ui/layout";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import { chakra } from "@chakra-ui/system";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
type propsType = {
  isOpen: boolean;
};
/* eslint-disable import/first */
import { FaUserAlt, FaLock } from "react-icons/fa";
import { useDispatch } from "react-redux";
import * as actionTypes from "../../redux/actionTypes";
const CFaUserAlt = chakra(FaUserAlt);
const CFaLock = chakra(FaLock);
import * as userAction from "../../redux/actions/user";
import { PinInput, PinInputField } from "@chakra-ui/pin-input";
const ForgetPassword: React.FC<propsType> = ({ isOpen }) => {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({ mode: "all" });

  const [success, setSuccess] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const handleClose = () => {
    dispatch({ type: actionTypes.HIDE_FORGET_PASSWORD });
    setSuccess(false);
    reset();
  };
  const [verification_code, setVerificationCode] = useState<
    number | string | any
  >("");
  const handleShowClick = () => {
    setShowPassword(!showPassword);
  };
  const onSubmit = async (values: any) => {
    const formData = new FormData();
    if (!success) {
      formData.set("email", values.email);
      formData.delete("verification_code");
      formData.delete("password");
      const res: any = await dispatch(userAction.sendOtpForReset(formData));
      if (res.status === 200) {
        setSuccess(true);
      }
    } else {
      formData.set("email", values.email);
      formData.set("verification_code", verification_code);
      formData.set("password", values.password);
      const res: any = await dispatch(userAction.forgetPassword(formData));
      if (res.status === 200) {
        dispatch({ type: actionTypes.HIDE_FORGET_PASSWORD });
        dispatch({ type: actionTypes.SHOW_LOGIN });
        reset();
      }
    }
  };
  const handleChange = (value: any) => {
    setVerificationCode(value);
  };
  return (
    <Modal
      blockScrollOnMount={true}
      size="lg"
      isOpen={isOpen}
      onClose={handleClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Forget Password</ModalHeader>
        <ModalCloseButton />
        <ModalBody minH="180px">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={4} p="1rem" backgroundColor="whiteAlpha.900">
              {!success && (
                <FormControl mb="5">
                  <InputGroup>
                    <InputLeftElement
                      top={1.5}
                      pointerEvents="none"
                      children={<CFaUserAlt h="full" color="gray.500" />}
                    />
                    <Input
                      fontSize={15}
                      py="6"
                      isInvalid={errors.email ? true : false}
                      errorBorderColor="red.500"
                      placeholder="Email address"
                      _placeholder={{ color: "var(--chakra-colors-gray-500)" }}
                      autoComplete="off"
                      {...register("email", {
                        required: "Email is required",
                        pattern: {
                          value:
                            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                          message: "Email is invalid",
                        },
                      })}
                    />
                  </InputGroup>

                  {errors.email && (
                    <div className="error">{errors.email.message}</div>
                  )}
                </FormControl>
              )}
              {success && (
                <>
                  <FormControl mb="5">
                    <InputGroup>
                      <InputLeftElement
                        top={1.5}
                        pointerEvents="none"
                        color="gray.300"
                        children={<CFaLock color="gray.500" />}
                      />
                      <Input
                        type={showPassword ? "text" : "password"}
                        placeholder="New Password *"
                        _placeholder={{
                          color: "var(--chakra-colors-gray-500)",
                        }}
                        fontSize={15}
                        py="6"
                        isInvalid={errors.password ? true : false}
                        errorBorderColor="red.500"
                        autoComplete="off"
                        {...register("password", {
                          required: "Password is required",
                          pattern: {
                            value: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/,
                            message:
                              "Must be at least one number, one lowercase, one uppercase letter and one special character ",
                          },
                          minLength: {
                            value: 8,
                            message: "Password should be at least 8 characters",
                          },
                        })}
                      />

                      <InputRightElement top={1.5} width="4.5rem">
                        <Button h="1.75rem" size="sm" onClick={handleShowClick}>
                          {showPassword ? (
                            <ViewIcon fontSize={15} color="gray.500" />
                          ) : (
                            <ViewOffIcon fontSize={15} color="gray.500" />
                          )}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                    {errors.password && (
                      <div className="error">{errors.password.message}</div>
                    )}
                  </FormControl>
                  <Text fontSize={15}>Enter Verification Code *</Text>
                  <FormControl>
                    <HStack>
                      <PinInput
                        type="number"
                        placeholder="0"
                        otp={true}
                        onChange={handleChange}
                        isInvalid={errors.verification_code ? true : false}
                        errorBorderColor="red.500"
                      >
                        <PinInputField
                          _placeholder={{
                            color: "var(--chakra-colors-gray-500)",
                          }}
                          border="1px solid var(--chakra-colors-gray-500)"
                          fontSize={15}
                          py="6"
                        />
                        <PinInputField
                          _placeholder={{
                            color: "var(--chakra-colors-gray-500)",
                          }}
                          fontSize={15}
                          py="6"
                        />
                        <PinInputField
                          _placeholder={{
                            color: "var(--chakra-colors-gray-500)",
                          }}
                          fontSize={15}
                          py="6"
                        />
                        <PinInputField
                          _placeholder={{
                            color: "var(--chakra-colors-gray-500)",
                          }}
                          fontSize={15}
                          py="6"
                        />
                      </PinInput>
                    </HStack>
                    {!verification_code && (
                      <div className="error">OTP Code is required</div>
                    )}
                  </FormControl>
                </>
              )}
              <Button
                borderRadius={0}
                type="submit"
                variant="solid"
                isLoading={isSubmitting}
                width="full"
                fontSize={15}
                py="6"
                backgroundColor="primary"
                color="whiteAlpha.800"
                rounded="md"
                _hover={{
                  color: "var(--chakra-colors-gray-500)",
                  backgroundColor: "#ddd",
                }}
              >
                {success ? "Confirm" : "Send OTP"}
              </Button>
            </Stack>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ForgetPassword;
