import React from "react";
import styled from "@emotion/styled";
import { Box } from "@chakra-ui/layout";
import { Image } from "@chakra-ui/image";
import { Link } from "react-router-dom";

const NotFound: React.FC = () => {
  return (
    <section className="section">
      <Container>
        <Box>
          <Image src="/404.svg" alt="Binary Entrance" boxSize="200px" />
        </Box>
        <h1>The page you are trying to reach cannot be found</h1>
        <Link to="/">
          <span>Go back to home page</span>
        </Link>
      </Container>
    </section>
  );
};

export default NotFound;

const Container = styled.div`
  min-height: 80vh;
  min-width: 100vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  h1 {
    text-align: center;
    font-size: 1.1rem;
    margin: 20px 0;
  }
  a {
    color: #0e62ac;
    font-size: 1rem;
  }
`;
