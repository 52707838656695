import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import Navbar from "../../components/Navbar";
import * as quizAction from "../../redux/actions/quiz";
import { State } from "../../redux/rootReducers";
import { quizStateType } from "../../redux/reducers/quiz";
import { useDispatch, useSelector } from "react-redux";
import { Box, Heading, ListItem, Text, UnorderedList } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/button";
import { useTimer } from "react-timer-hook";
import cogoToast from "cogo-toast";
import * as actionTypes from "../../redux/actionTypes";
import { useParams } from "react-router-dom";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import "katex/dist/katex.min.css";
import Latex from "react-latex-next";
import PrivateLayout from "../../components/PrivateLayout";
import { useNavigate, useLocation } from "react-router-dom";
import { Spinner } from "@chakra-ui/spinner";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { chakra } from "@chakra-ui/system";
import { userStateType } from "../../redux/reducers/user";
// import { userStateType } from "src/redux/reducers/user";
const CFiChevronDown = chakra(FiChevronDown);
const CFiChevronUp = chakra(FiChevronUp);

const MockTest: React.FC = () => {
  const params: any = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [vLoading, setVLoading] = useState<boolean>(true);
  const quizState = useSelector<State, quizStateType>((state) => state.quiz);
  const { loading, mcqs, isSubmitted, subjects, submitting, showSuccess } =
    quizState;
  // const userState = useSelector<State, userStateType>((state) => state.user);
  const [answerSheet, setAnswerSheet] = useState<{}>({});
  const [disabled, setDisabled] = useState<boolean>(false);
  const [started, setStarted] = useState<boolean>(false);
  const [showSheet, setShowSheet] = useState<boolean>(false);
  const userState = useSelector<State, userStateType>((state) => state.user);
  const { isVerified, isAuthenticated } = userState;
  const [confirmSubmit, setConfirmSubmit] = useState<boolean>(false);
  const time = new Date();
  time.setTime(parseInt(new URLSearchParams(location.search).get("et") as any));

  const initializeSheet = (length: number) => {
    let sheet = {};
    for (let i = 1; i <= length; i++) {
      sheet = { ...sheet, [i]: "" };
    }
    setAnswerSheet(sheet);
  };

  const handleChooseOption = (option: string, id: number | string) => {
    dispatch(quizAction.updateAnswerSheet(option, id));
  };
  const handleSubmit = async () => {
    setConfirmSubmit(false);
    pause();
    const answers = mcqs.reduce(
      (acc, cur, i) => ((acc[cur.id] = cur.choosed_option), acc),
      {}
    );
    const formData = new FormData();
    formData.set("course", params.course as string);
    formData.set("section", params.title as string);
    formData.set("answer_data", JSON.stringify(answers));
    const res = await dispatch(quizAction.submitMockTest(formData));
  };
  const { seconds, minutes, days, hours, pause, isRunning } = useTimer({
    autoStart: true,
    expiryTimestamp: time,
    onExpire: () => {
      setDisabled(true);
      handleSubmit();
    },
  });
  const renderOption = (content: any) => <Latex>{content}</Latex>;
  const validate = async (id: any) => {
    const res = await quizAction.getSectionById(id);
    if (res?.status === 200) {
      if (
        new Date(res.data?.start_time)?.getTime() !==
          parseInt(new URLSearchParams(location.search).get("st") as any) ||
        new Date(res.data?.end_time)?.getTime() !==
          parseInt(new URLSearchParams(location.search).get("et") as any)
      ) {
        navigate(`/${params.course}`);
        return cogoToast.error("Invalid url.", {
          position: "top-center",
        });
      }
      if (
        new Date(res.data?.start_time)?.getTime() <= new Date().getTime() &&
        new Date(res.data?.end_time)?.getTime() >= new Date().getTime()
      ) {
        setVLoading(false);
      } else {
        navigate(`/${params.course}`);
        return cogoToast.error("Time up. Please be in time next time.", {
          position: "top-center",
        });
      }
    } else if (!res || res?.status === 500) {
      navigate(`/${params.course}`);
      return cogoToast.error("Internal Server Error", {
        position: "top-center",
      });
    } else if (res?.status === 404) {
      navigate(`/${params.course}`);
      return cogoToast.error("Model set not found in the server.", {
        position: "top-center",
      });
    }
  };
  useEffect(() => {
    dispatch(quizAction.getMCQ(params.course, params.title));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);
  window.onbeforeunload = function () {
    return "Are you really want to perform the action?";
  };
  useEffect(() => {
    if (mcqs.length > 0) {
      initializeSheet(mcqs.length);
    }
  }, [mcqs]);
  useEffect(() => {
    if (location.search) {
      const st = new URLSearchParams(location.search).get("st");
      const et = new URLSearchParams(location.search).get("et");
      const id = new URLSearchParams(location.search).get("id");
      if (!st || !et || !id) {
        cogoToast.error("Invalid url", { position: "top-center" });
        navigate(`/${params.course}`);
        return;
      }
      validate(id);
    } else {
      cogoToast.error("Invalid url", { position: "top-center" });
      navigate(`/${params.course}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);
  useEffect(() => {
    dispatch({ type: actionTypes.SUBMIT_FALSE });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (isAuthenticated && !isVerified) {
      navigate(`/test/${params.course}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVerified, isAuthenticated]);
  return (
    <PrivateLayout>
      <Navbar active="active" />
      {isAuthenticated && isVerified ? (
        <>
          {!vLoading ? (
            <>
              <Modal isOpen={!started} onClose={() => {}} size="lg">
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>
                    {loading ? (
                      <Box d="flex" gridGap="10px" alignItems="center">
                        <Spinner
                          thickness="4px"
                          speed="0.75s"
                          emptyColor="#0e62ac55"
                          color="#0e62ac"
                          h="2rem"
                          w="2rem"
                        />
                        <Heading as="span" fontSize="1rem" fontWeight={500}>
                          Fetching {params.title}...
                        </Heading>
                      </Box>
                    ) : (
                      <Heading as="span" fontSize="1.3rem" fontWeight={500}>
                        Accepts the terms and start to continue.
                      </Heading>
                    )}
                  </ModalHeader>
                  <ModalBody fontSize={14}>
                    <UnorderedList>
                      <ListItem>
                        This is a online live mock test of 2 hours.
                      </ListItem>
                      <ListItem>
                        The test starts automatically in the given schedule.
                      </ListItem>
                      <ListItem>
                        You must submit your answer sheet before the timer ends.
                      </ListItem>
                      <ListItem>
                        The answer sheet will be automatically submitted in case
                        your timer ends.
                      </ListItem>
                      <ListItem>
                        If you mistakenly reload the page or go back from the
                        page during the test, the changes may not be saved and
                        your result will not be published.
                      </ListItem>
                    </UnorderedList>
                  </ModalBody>

                  <ModalFooter>
                    <Button
                      fontSize="15px"
                      bg="#0e62ac"
                      py={["8px", "10px", "15px"]}
                      color="#eee"
                      fontWeight={600}
                      border="none"
                      outline="none"
                      px={["15px", "20px"]}
                      fontFamily="inherit"
                      _hover={{
                        color: "var(--chakra-colors-white)",
                      }}
                      disabled={loading}
                      cursor="pointer"
                      onClick={() => setStarted(true)}
                    >
                      Start Test
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
              <Modal
                isOpen={confirmSubmit}
                blockScrollOnMount={true}
                onClose={() => setConfirmSubmit(false)}
                size="md"
              >
                <ModalOverlay />
                <ModalCloseButton />
                <ModalContent>
                  <ModalBody fontSize={14} minH="80px">
                    <Text fontSize="18px" as="h2" textAlign="center">
                      Mock test submission
                    </Text>
                    <Text fontSize="16px" mt="8px" textAlign="center">
                      Are you sure to submit ?
                    </Text>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      fontSize="15px"
                      bg="#0e62ac"
                      py={["8px", "10px", "15px"]}
                      color="#eee"
                      fontWeight={600}
                      border="none"
                      outline="none"
                      px={["10px", "15px"]}
                      fontFamily="inherit"
                      _hover={{
                        color: "var(--chakra-colors-white)",
                      }}
                      cursor="pointer"
                      onClick={() => handleSubmit()}
                    >
                      Submit
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
              <Modal
                isOpen={showSuccess}
                blockScrollOnMount={true}
                onClose={() => {}}
                size="lg"
              >
                <ModalOverlay />
                <ModalContent>
                  <ModalBody fontSize={14} minH="150px">
                    <Text fontSize={18} as="h2" textAlign="center">
                      Success
                    </Text>
                    <Text fontSize={14} textAlign="center">
                      Thank you for participating in online live mock test.
                      Check your result.
                    </Text>
                  </ModalBody>
                  <ModalFooter mt="40px">
                    <Button
                      fontSize="15px"
                      bg="#0e62ac"
                      py={["8px", "10px", "15px"]}
                      color="#eee"
                      fontWeight={600}
                      border="none"
                      outline="none"
                      px={["15px", "20px"]}
                      fontFamily="inherit"
                      _hover={{
                        color: "var(--chakra-colors-white)",
                      }}
                      cursor="pointer"
                      onClick={() => {
                        if (window) {
                          window.scrollTo({
                            x: 0,
                            y: 0,
                            behavior: "smooth",
                          } as any);
                        }
                        dispatch({ type: actionTypes.HIDE_SUCCESS });
                        navigate(`/test/${params.course}`);
                      }}
                    >
                      Go back
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
              <InfoSticky>
                <Box
                  d="flex"
                  alignItems="center"
                  gridGap="10px"
                  justifyContent="space-between"
                >
                  <Box d="flex" alignItems="center" gridGap="8px">
                    <Text
                      fontSize={[14, 14, 14, 18]}
                      color="primary"
                      whiteSpace="nowrap"
                    >
                      Time Remaining :
                    </Text>
                    <Text
                      fontSize={[16, 17, 18, 25]}
                      fontWeight="600"
                      color="gray.800"
                    >
                      {days > 0 && <>{`${days}`.padStart(2, "0")}:</>}
                      {`${hours}`.padStart(2, "0")}:
                      {`${minutes}`.padStart(2, "0")}:
                      {`${seconds}`.padStart(2, "0")}
                    </Text>
                  </Box>

                  <Box>
                    {!isSubmitted && (
                      <Button
                        fontSize="15px"
                        bg="#0e62ac"
                        py={["14px", "14px", "15px"]}
                        color="#eee"
                        fontWeight={600}
                        border="none"
                        outline="none"
                        px={["8px", "20px"]}
                        w={["full", "fit-content"]}
                        fontFamily="inherit"
                        _hover={{
                          color: "var(--chakra-colors-white)",
                        }}
                        cursor="pointer"
                        disabled={submitting}
                        isLoading={submitting}
                        loadingText="Submitting..."
                        onClick={() => setConfirmSubmit(true)}
                      >
                        {submitting && !isRunning ? "Submitting..." : "Submit"}
                      </Button>
                    )}
                  </Box>
                </Box>
                <Box mt="6">
                  <Box textAlign="center">
                    <Button
                      fontSize="7"
                      py="14px"
                      px="10px"
                      bg="#ffffff66"
                      color="primary"
                      onClick={() => setShowSheet(!showSheet)}
                      rightIcon={
                        showSheet ? (
                          <CFiChevronUp size={20} />
                        ) : (
                          <CFiChevronDown size={20} />
                        )
                      }
                    >
                      {showSheet ? "Hide Sheet" : "Show Sheet"}
                    </Button>
                  </Box>
                  {showSheet && (
                    <Box mt="4">
                      <div className="grid">
                        {Object.keys(answerSheet).map((number, index) => (
                          <div
                            key={`${index}`}
                            className={
                              mcqs?.[index]?.is_choosed
                                ? "checked"
                                : "unchecked"
                            }
                          >
                            {mcqs?.[index]?.is_choosed
                              ? `${number}. ${mcqs?.[index]?.choosed_option}`
                              : number}
                          </div>
                        ))}
                      </div>
                    </Box>
                  )}
                </Box>
              </InfoSticky>
              <div className="divider-md"></div>
              <Container>
                <QuestionSheet>
                  <div className="question-header">
                    <div className="main-header">
                      <h1 className="university-title">Tribhuvan University</h1>
                      <h1 className="department-title">
                        Institute of Computer Science and Information Technology
                      </h1>
                      <h1 className="question-title">{params.title}</h1>
                    </div>
                    <div className="sub-header">
                      <div className="left">
                        <span>{params?.course}</span>
                        <span>Time: 2hrs</span>
                      </div>
                      <div className="right">
                        <span>Full Marks: 100</span>
                        <span>Pass Marks: 35</span>
                      </div>
                    </div>
                  </div>
                  <div className="question-body">
                    {subjects[params?.course].map(
                      (subject: string, index: number) => (
                        <Category key={`${index}`}>
                          {!loading && (
                            <h1 className="category-title">{subject}</h1>
                          )}
                          {!loading &&
                            mcqs
                              ?.filter((mcq) => mcq.subject === subject)
                              .map((item, index) => (
                                <QuestionGroup key={`${index}`}>
                                  <p className="question">
                                    {item.index + 1}.{" "}
                                    {renderOption(item.question)}
                                  </p>
                                  <div className="answer-container">
                                    {Object.keys(item.options).map(
                                      (option, index) => (
                                        <div
                                          className="answer"
                                          key={`${index}`}
                                        >
                                          <span className="option-index">
                                            {option}.
                                          </span>{" "}
                                          <input
                                            name={`${item.id}`}
                                            type="radio"
                                            className="radio-choose"
                                            checked={
                                              item.choosed_option === option
                                            }
                                            disabled={isSubmitted}
                                            onChange={(e) =>
                                              isSubmitted
                                                ? () => {}
                                                : disabled
                                                ? cogoToast.warn(
                                                    "Time is up. Please submit and start next model question"
                                                  )
                                                : e.target.checked
                                                ? handleChooseOption(
                                                    option,
                                                    item.id
                                                  )
                                                : {}
                                            }
                                          />{" "}
                                          <span className="option">
                                            {renderOption(item.options[option])}
                                          </span>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </QuestionGroup>
                              ))}
                        </Category>
                      )
                    )}
                  </div>
                </QuestionSheet>
                <Box className="sticky-container">
                  <AnswerSheet>
                    <div className="answer-sheet-container">
                      <Box mb="10px" d="flex" alignItems="center" gridGap="8px">
                        <Text
                          fontSize={[12, 13, 13, 18]}
                          color="primary"
                          whiteSpace="nowrap"
                        >
                          Time Remaining :{" "}
                        </Text>
                        <Text
                          fontSize={[14, 15, 16, 25]}
                          fontWeight="600"
                          color="gray.800"
                        >
                          {days > 0 && <>{`${days}`.padStart(2, "0")}:</>}
                          {`${hours}`.padStart(2, "0")}:
                          {`${minutes}`.padStart(2, "0")}:
                          {`${seconds}`.padStart(2, "0")}
                        </Text>
                      </Box>
                      <h1 className="as-title">Answer Sheet</h1>
                      <div className="as-selected-title">
                        {loading ? "Initializing..." : "Selected answers key"}
                      </div>
                      <div className="grid">
                        {Object.keys(answerSheet).map((number, index) => (
                          <div
                            key={`${index}`}
                            className={
                              mcqs?.[index]?.is_choosed
                                ? "checked"
                                : "unchecked"
                            }
                          >
                            {mcqs?.[index]?.is_choosed
                              ? `${number}. ${mcqs?.[index]?.choosed_option}`
                              : number}
                          </div>
                        ))}
                      </div>
                      <Box my="15px">
                        {!isSubmitted && (
                          <Button
                            fontSize="15px"
                            bg="#0e62ac"
                            py={["14px", "14px", "15px"]}
                            color="#eee"
                            fontWeight={600}
                            border="none"
                            outline="none"
                            px={["8px", "20px"]}
                            w={["full", "fit-content"]}
                            fontFamily="inherit"
                            _hover={{
                              color: "var(--chakra-colors-white)",
                            }}
                            cursor="pointer"
                            disabled={isSubmitted || submitting}
                            onClick={() => setConfirmSubmit(true)}
                          >
                            {submitting && !isRunning
                              ? "Submitting please wait..."
                              : "Confirm & Submit"}
                          </Button>
                        )}
                      </Box>
                    </div>
                  </AnswerSheet>
                </Box>
              </Container>{" "}
            </>
          ) : (
            <Container>
              <div className="divider-md"></div>
              <Box h="fit-content" d="flex" gridGap="10px" alignItems="center">
                <Spinner
                  thickness="4px"
                  speed="0.75s"
                  emptyColor="#0e62ac55"
                  color="#0e62ac"
                  h="2.5rem"
                  w="2.5rem"
                />
                <Heading as="span" fontSize="1rem" fontWeight={500}>
                  Validating schedule, Please wait...
                </Heading>
              </Box>
              <div className="divider-md"></div>
            </Container>
          )}{" "}
        </>
      ) : (
        <Container>
          <>
            <div className="divider-md"></div>
            <Box d="flex" h="fit-content" gridGap="10px" alignItems="center">
              <Spinner
                thickness="4px"
                speed="0.75s"
                emptyColor="#0e62ac55"
                color="#0e62ac"
                h="2.5rem"
                w="2.5rem"
              />
              <Heading as="span" fontSize="1rem" fontWeight={500}>
                Verifying user, Please wait...
              </Heading>
            </Box>
            <div className="divider-md"></div>
          </>
        </Container>
      )}
    </PrivateLayout>
  );
};

export default MockTest;
const Container = styled.div`
  max-width: 1600px;
  display: flex;
  gap: 30px;
  margin: 90px auto 0;
  padding: 1.8rem 3rem;
  .sticky-container {
    @media only screen and (max-width: 678px) {
      display: none;
    }
  }
  @media only screen and (max-width: 750px) {
    padding: 1.5rem 1.8rem;
    margin: 70px auto 0;
  }
  @media only screen and (max-width: 678px) {
    margin: 4.3rem auto 0;
  }
  @media only screen and (max-width: 567px) {
    padding: 1.5rem;
  }
`;

const QuestionSheet = styled.div`
  flex: 1;
  .question-header {
    .main-header {
      .university-title {
        text-align: center;
        font-weight: 500;
        font-size: 1.1rem;
      }
      .department-title {
        text-align: center;
        font-weight: 400;
        margin: 5px 0;
        font-size: 1.1rem;
      }
      .question-title {
        text-transform: uppercase;
        text-align: center;
        font-weight: 600;
        font-size: 0.95rem;
      }
    }
    .sub-header {
      border: 1px solid #222;
      padding: 6px 8px;
      margin: 20px 0;
      display: flex;
      justify-content: space-between;
      .left {
        span {
          display: block;
          width: fit-content;
          font-weight: 500;
          font-size: 14px;
          line-height: 26px;
        }
      }
      .right {
        span {
          display: block;
          width: fit-content;
          font-weight: 500;
          font-size: 14px;
          line-height: 26px;
        }
      }
    }
  }
  .question-body {
    .category-title {
      font-size: 17px;
      font-weight: 600;
    }
  }
`;
const QuestionGroup = styled.div`
  margin: 25px 0;
  padding: 15px;
  border: 1px solid #aaa;
  border-radius: 8px;
  @media only screen and (max-width: 750px) {
    padding: 10px 8px;
  }
  &.correct {
    background-color: #04aa6d99;
  }
  &.incorrect {
    background-color: #ff808099;
  }
  .question {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 15px;
  }
  .answer-container {
    display: grid;
    gap: 8px;
    grid-template-columns: repeat(2, 1fr);
    .answer {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 6px;
      .radio-choose {
        appearance: none;
        height: 20px;
        width: 20px;
        min-width: 20px;
        border: 1px solid #777777;
        border-radius: 50%;
        cursor: pointer;
        overflow: hidden;
        &:checked {
          border: 5px solid #0e62ac;
          /* background-image: url("https://uxwing.com/wp-content/themes/uxwing/download/48-checkmark-cross/blue-check-mark.png");
          background-size: cover;
          background-repeat: no-repeat; */
        }
        &:disabled {
          cursor: text;
        }
      }
      .option-index {
        font-size: 14.5px;
      }
      .option {
        font-size: 14.5px;
      }
    }
  }
`;

const Category = styled.div`
  margin: 25px 0;
`;

const AnswerSheet = styled.div`
  width: 350px;
  position: sticky;
  top: 7.5rem;
  display: block;
  .answer-sheet-container {
    border: 1px solid #0e62ac;
    border-radius: 8px;
    padding: 15px;
    .as-title {
      font-weight: 500;
      font-size: 20px;
    }
    .as-selected-title {
      font-weight: 400;
      font-size: 14px;
      margin-top: 5px;
      color: #333;
    }
    .grid {
      margin-top: 15px;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(40px, 1fr));
      grid-auto-rows: minmax(40px, 40px);
      grid-auto-flow: dense;
      gap: 1px;
      max-height: 280px;
      overflow-x: hidden;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 5px;
      }
      ::-webkit-scrollbar-thumb {
        background: #aaaaaa;
        border-radius: 6px;
        cursor: pointer;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #0e62ac;
      }
      div {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 0.7rem;
        border: 0.5px solid #bbb;
        &.checked {
          background-color: #0e62ac;
          color: #ffffff;
        }
        &.unchecked {
          background-color: #0e62ac66;
          color: #ffffff;
        }
      }
      @media only screen and (max-width: 1027px) {
        grid-template-columns: repeat(auto-fit, minmax(40px, 1fr));
      }
      @media only screen and (max-width: 927px) {
        grid-template-columns: repeat(auto-fit, minmax(40px, 1fr));
      }
    }
  }
  @media only screen and (max-width: 1027px) {
    width: 300px;
  }
  @media only screen and (max-width: 927px) {
    width: 240px;
  }
`;

const InfoSticky = styled.div`
  display: none;
  position: sticky;
  background-color: #e1e1e1;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  top: 90px;
  max-width: 1600px;
  z-index: 5;
  padding: 1.5rem 3rem;
  .grid {
    /* margin-top: 15px; */
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(40px, 1fr));
    grid-auto-rows: minmax(40px, 40px);
    grid-auto-flow: dense;
    gap: 1px;
    animation: heightAnimate 2s ease-out;
    opacity: 1;
    @keyframes heightAnimate {
      from {
        height: 0;
      }
      to {
        height: auto;
      }
    }
    &::-webkit-scrollbar {
      width: 5px;
    }
    ::-webkit-scrollbar-thumb {
      background: #aaaaaa;
      border-radius: 6px;
      cursor: pointer;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #0e62ac;
    }
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      font-size: 0.7rem;
      border: 0.5px solid #bbb;
      &.checked {
        background-color: #0e62ac;
        color: #ffffff;
      }
      &.unchecked {
        background-color: #0e62ac66;
        color: #ffffff;
      }
    }
  }
  @media only screen and (max-width: 750px) {
    padding: 1.2rem 1.8rem;
    top: 70px;
  }
  @media only screen and (max-width: 678px) {
    display: block;
  }
  @media only screen and (max-width: 567px) {
    padding: 0.5rem 1.5rem;
  }
`;
