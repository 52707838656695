import React, { useEffect } from "react";
interface Props {
  title?: string;
  description?: string;
}
/* eslint-disable import/first */
import { motion } from "framer-motion";
import HeroSliderContainer from "./styled";
const HeroSlider: React.FC<Props> = ({ title, description }) => {
  useEffect(() => {}, []);
  return (
    <HeroSliderContainer>
      <h1>{title}</h1>
      <motion.p
        initial={{ opacity: 0, y: "1rem" }}
        transition={{ opacity: { duration: 1 }, y: { duration: 1 } }}
        animate={{ opacity: [0, 1], y: ["1rem", "0rem"] }}
      >
        {description ||
          "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Laborumearum nemo ratione excepturi inventore cum id aut facilis delectusvel."}
      </motion.p>
    </HeroSliderContainer>
  );
};

export default HeroSlider;
