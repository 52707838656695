import { Box } from "@chakra-ui/layout";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getGallery } from "../../redux/actions/gallery";
import { galleryStateType } from "../../redux/reducers/gallery";
import { State } from "../../redux/rootReducers";
import GalleryItem from "../GalleryItem";
import styled from "@emotion/styled";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
const Gallery: React.FC = () => {
  const dispatch = useDispatch();
  const galleryState = useSelector<State, galleryStateType>(
    (state) => state.gallery
  );
  const { galleries } = galleryState;
  const options = {
    buttons: {
      backgroundColor: "#0e62ac",
      iconColor: "#eee",
      showDownloadButton: false,
    },
    settings: {
      overlayColor: "rgba(255, 237, 225, 1)",
      transitionSpeed: 1000,
      transitionTimingFunction: "linear",
      backgroundColor: "#000",
    },
    thumbnails: {
      thumbnailsSize: ["120px", "100px"],
      thumbnailsOpacity: 0.2,
    },
    caption: {
      captionColor: "#0e62ac",
      captionFontWeight: "500",
      captionFontSize: "20px",
    },
    progressBar: {
      size: "4px",
      backgroundColor: "rgba(255, 237, 225, 1)",
      fillColor: "#0e62ac",
    },
  };

  useEffect(() => {
    dispatch(getGallery());
  }, []);
  return (
    <GallerySection className="section">
      <h1 className="section-title">Gallery</h1>
      <SimpleReactLightbox>
        <SRLWrapper options={options}>
          <Box className="gallery-container" mt="2rem">
            {galleries?.map((gallery: any, index: number) => (
              <GalleryItem
                image={gallery.image}
                title={gallery.title}
                index={index}
              />
            ))}
          </Box>
        </SRLWrapper>
      </SimpleReactLightbox>
    </GallerySection>
  );
};

export default Gallery;

const GallerySection = styled.div`
  max-width: 1600px;
  margin: 0 auto;
  background: "#dddddd";
  .gallery-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    grid-auto-rows: minmax(120px, 180px);
    grid-auto-flow: dense;
    grid-gap: 1.5rem;
  }
`;
