import { ChakraProvider, extendTheme, CSSReset } from "@chakra-ui/react";
import { css, Global } from "@emotion/react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Routes, Route, Navigate } from "react-router-dom";
import NoticeDetails from "./components/NoticeDetails";
import BCA from "./pages/Bca";
import BIT from "./pages/Bit";
// import Blogs from "./pages/Blogs";
import CMAT from "./pages/Cmat";
import CSIT from "./pages/Csit";
import Home from "./pages/Home";
import Mcq from "./pages/Mcq";
import MockTest from "./pages/MockTest";
import MockTestRegister from "./pages/MockTestRegister";
import NotFound from "./pages/NotFound";
import Notice from "./pages/Notice";
import OnlineMocktestResult from "./pages/OnlineMocktestResult";
import PhysicalMocktestResult from "./pages/PhysicalMocktestResult";
import Result from "./pages/Result";
import Test from "./pages/Test";
import * as userAction from "./redux/actions/user";
function App() {
  const dispatch = useDispatch();
  const colors = {
    primary: "#0e62ac",
  };
  const theme = extendTheme({
    colors,
    fonts: {
      heading: "Montserrat",
      body: "Montserrat",
    },
    shadows: { outline: "none" },
    sizes: {
      container: {
        mw: "1600px",
      },
    },
  });
  const GlobalStyles = css`
    .js-focus-visible :focus:not([data-focus-visible-added]) {
      outline: none;
      box-shadow: none;
    }
    html {
      font-size: 100%;
    }
    body {
      background: #e9e9e9;
    }
  `;
  const authenticate = async () => {
    if (userAction.getToken()) {
      dispatch(userAction.getUserProfile());
    }
  };
  useEffect(() => {
    authenticate();
  }, []);
  return (
    <ChakraProvider theme={theme}>
      <Global styles={GlobalStyles} />
      <CSSReset />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/bca" element={<BCA />} />
        <Route path="/bit" element={<BIT />} />
        <Route path="/cmat" element={<CMAT />} />
        <Route path="/csit" element={<CSIT />} />
        <Route path="/notice" element={<Notice />} />
        {/* <Route path="/blogs" element={<Blogs />} /> */}
        <Route path="/mocktest/register" element={<MockTestRegister />} />
        <Route path="/result/physical" element={<Result />} />
        <Route
          path="/result/physical/:id"
          element={<PhysicalMocktestResult />}
        />
        <Route path="/result/mocktest" element={<OnlineMocktestResult />} />
        <Route path="/notice/:id" element={<NoticeDetails />} />
        <Route path="/test/:course" element={<Test />} />
        <Route path="/test/:course/:title" element={<Mcq />} />
        <Route path="/mocktest/:course/:title" element={<MockTest />} />
        <Route path="/404" element={<NotFound />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
    </ChakraProvider>
  );
}

export default App;
