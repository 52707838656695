import React, { useEffect, useState } from "react";
import Logo from "../Logo";
import Nav from "./styled";
import * as actionTypes from "../../redux/actionTypes";
import { Button, ButtonProps, IconButton } from "@chakra-ui/button";
import {
  Menu,
  MenuItem,
  MenuButton,
  MenuList,
  HStack,
  Image,
  Text,
  useMediaQuery,
  Tooltip,
  Divider,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { Link as NextLink, useLocation, useNavigate } from "react-router-dom";
import { Flex, Link } from "@chakra-ui/react";
import { FiLogOut } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../redux/rootReducers";
import { userStateType } from "../../redux/reducers/user";
import * as userAction from "../../redux/actions/user";
import { MdVerified } from "react-icons/md";
import { BsCardChecklist } from "react-icons/bs";
import { RiMenu2Line } from "react-icons/ri";
import { FiX } from "react-icons/fi";
type NavProps = {
  active?: string;
};

const Navbar: React.FC<NavProps> = ({ active }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [navActive, setNavActive] = useState("");
  const [smallHeight] = useMediaQuery("(max-height: 600px)");
  const navigate = useNavigate();
  window.addEventListener("scroll", () => {
    if (typeof active !== "undefined") return;
    if (window.scrollY > 50) {
      setNavActive("active");
    } else {
      setNavActive("");
    }
  });
  useEffect(() => {
    if (typeof active !== "undefined") {
      setNavActive("active");
    }
  }, [active]);
  const MotionButton = motion<ButtonProps>(Button);
  const showLogin = () => {
    dispatch({
      type: actionTypes.SHOW_LOGIN,
    });
  };
  const handleLogout = () => {
    dispatch(userAction.logout());
    dispatch({ type: actionTypes.SET_LOGGED_OUT, payload: true });
  };
  const userState = useSelector<State, userStateType>((state) => state.user);
  const {
    isAuthenticated,
    profile: { first_name, image, last_name, BEID },
    isVerified,
  } = userState;
  const [show, setShow] = useState<boolean>(false);
  const handleChoose = () => {
    setShow(false);
    window.scrollTo({ top: 500, behavior: "smooth" });
  };

  return (
    <Nav className={smallHeight ? "active" : `${navActive}`}>
      <div className={show ? "nav-container show" : "nav-container"}>
        <Logo height="2.9rem" />
        <IconButton
          aria-label="Menu"
          bg="transparent"
          className="menu-open"
          h="fit-content"
          d="flex"
          alignItems="center"
          icon={<RiMenu2Line color="var(--chakra-colors-primary)" size="30" />}
          _hover={{ bg: "transparent" }}
          _active={{ bg: "transparent" }}
          onClick={() => setShow(true)}
        />
        <IconButton
          aria-label="Menu"
          bg="transparent"
          d="flex"
          alignItems="center"
          h="fit-content"
          className="menu-close"
          onClick={() => setShow(false)}
          icon={<FiX color="var(--chakra-colors-red-500)" size="30" />}
          _hover={{ bg: "transparent" }}
          _active={{ bg: "transparent" }}
        />
        <Flex className={show ? "main-menu active" : "main-menu"}>
          {/* <FormControl w={["200px", "300px", "400px"]}>
            <InputGroup>
              <Input
                type="text"
                py="8"
                placeholder="Search resources, exams, notice"
                fontSize={[14, 15, 16]}
                borderWidth="2px"
                borderColor={navActive === "active" ? "primary" : "#ddds"}
              />

              <InputRightAddon h="100%" p="0">
                <Button py="8" fontSize={[14, 15, 16]} borderWidth="1px">
                  Search
                </Button>
              </InputRightAddon>
            </InputGroup>
          </FormControl> */}
          {/* <NextLink to="/blogs">
            <Link
              onClick={() => setShow(false)}
              fontSize="1.1rem"
              color="primary"
              fontWeight="500"
              as={Button}
              textDecor={location.pathname === "/blogs" ? "underline" : "none"}
              bg="transparent"
              p="0"
              boxShadow="none"
              _hover={{ bg: "transparent", textDecor: "underline" }}
            >
              Blogs
            </Link>
          </NextLink> */}
          <NextLink to="/notice">
            <Link
              onClick={() => setShow(false)}
              fontSize="1.1rem"
              color="primary"
              fontWeight="500"
              as={Button}
              px="2"
              bg="transparent"
              boxShadow="none"
              textDecor={location.pathname === "/notice" ? "underline" : "none"}
              _hover={{ bg: "transparent", textDecor: "underline" }}
            >
              Notice
            </Link>
          </NextLink>
          <NextLink to="/result/physical">
            <Link
              onClick={() => setShow(false)}
              fontSize="1.1rem"
              color="primary"
              fontWeight="500"
              as={Button}
              bg="transparent"
              p="0"
              boxShadow="none"
              textDecor={location.pathname === "/result" ? "underline" : "none"}
              _hover={{ bg: "transparent", textDecor: "underline" }}
            >
              Result
            </Link>
          </NextLink>
          {!isAuthenticated && (
            <Link
              fontSize="1.1rem"
              background="transparent"
              color="primary"
              _hover={{
                background: "transparent",
                textDecor: "underline",
              }}
              px="2"
              fontWeight="500"
              onClick={showLogin}
              as={Button}
            >
              Login
            </Link>
          )}
          {isAuthenticated && (
            <Menu>
              <MenuButton
                bg="#0e62ac33"
                boxShadow="rgba(0, 0, 0, 0.18) 0px 2px 4px"
                color=""
                fontSize="1.1rem"
                borderRadius="18px"
                as={Button}
                px="0"
                paddingRight="10px"
                h="fit-content"
              >
                <HStack>
                  <Image
                    src={image}
                    borderRadius="full"
                    boxSize="40px"
                    alt={first_name}
                    fallbackSrc="https://bit.ly/3FJ2laR"
                  />
                  <Text
                    fontSize={15}
                    color="var(--chakra-colors-primary)"
                    textTransform="capitalize"
                    d="flex"
                    alignItems="center"
                    gridGap="5px"
                  >
                    {first_name}
                    {isVerified && (
                      <Tooltip
                        hasArrow
                        label="Verified"
                        bg="gray.400"
                        color="primary"
                      >
                        <MdVerified
                          color="var(--chakra-colors-green-500)"
                          fontSize="15px"
                        />
                      </Tooltip>
                    )}
                  </Text>
                </HStack>
              </MenuButton>
              <MenuList
                bg="#ffffff"
                zIndex={5}
                minW="180px"
                py={5}
                minH="150px"
                borderRadius="10px"
                boxShadow="rgba(7, 4, 4, 0.18) 0px 2px 4px"
              >
                <MenuItem cursor="text" px={25}>
                  <Flex direction="column">
                    <Text as="h3" fontSize="17px" color="primary">
                      {first_name} {last_name}
                    </Text>
                    {isVerified && (
                      <Text as="p" fontSize="14px" color="#777777">
                        BEID:{" "}
                        <Text
                          display="inline-block"
                          fontSize="16px"
                          fontWeight="500"
                        >
                          {BEID}
                        </Text>
                      </Text>
                    )}
                  </Flex>
                </MenuItem>
                <Divider h="10px" />
                {isVerified && (
                  <>
                    <MenuItem
                      px={25}
                      bg="transparent"
                      _hover={{ bg: "transparent" }}
                      fontSize="1.1rem"
                      onClick={() => {
                        setShow(false);
                        navigate("/result/mocktest");
                      }}
                    >
                      <HStack>
                        <BsCardChecklist
                          color="var(--chakra-colors-primary)"
                          fontSize="15px"
                        />
                        <Text
                          fontSize={15}
                          color="var(--chakra-colors-primary)"
                        >
                          Online Mocktest Result
                        </Text>
                      </HStack>
                    </MenuItem>
                    <Divider />{" "}
                  </>
                )}
                <MenuItem
                  px={25}
                  bg="transparent"
                  _hover={{ bg: "transparent" }}
                  fontSize="1.1rem"
                  onClick={() => {
                    setShow(false);
                    handleLogout();
                  }}
                >
                  <HStack>
                    <FiLogOut
                      color="var(--chakra-colors-primary)"
                      fontSize="15px"
                    />
                    <Text fontSize={15} color="var(--chakra-colors-primary)">
                      Logout
                    </Text>
                  </HStack>
                </MenuItem>
              </MenuList>
            </Menu>
          )}
          {location.pathname === "/" && (
            <MotionButton
              p="10px 2rem"
              fontSize="1.1rem"
              borderRadius="2rem"
              h="fit-content"
              fontFamily="inherit"
              outline="none"
              cursor="pointer"
              bg="#0e62ac"
              color="#eee"
              border="none"
              fontWeight="500"
              _hover={{
                color: "var(--chakra-colors-primary)",
                backgroundColor: "var(--chakra-colors-white-400)",
              }}
              initial={{ scale: 1 }}
              whileHover={{ scale: 0.95 }}
              onClick={() => handleChoose()}
            >
              Online Test
            </MotionButton>
          )}
        </Flex>
      </div>
    </Nav>
  );
};

export default Navbar;
