import React, { useEffect } from "react";
import AppLayout from "../../components/AppLayout";
import styled from "@emotion/styled";
import {
  Heading,
  Text,
  FormControl,
  Input,
  FormLabel,
  Divider,
  RadioGroup,
  Stack,
  Radio,
  Button,
} from "@chakra-ui/react";
import cogoToast from "cogo-toast";
const MockTestRegister: React.FC = () => {
  const onSubmit = (e: any) => {
    e.preventDefault();
    cogoToast.warn("Form expired. Will be available soon.", {
      position: "top-center",
    });
  };
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <AppLayout>
      <div className="divider-md"></div>
      <RegisterSection>
        <Heading as="h2" fontWeight={500} fontSize={["20px", "25px"]}>
          Registration for Physical Mock Test
        </Heading>
        <Text fontSize={["12px", "13px"]} mt="2">
          Binary Entrance presents: Physical Mock Test for CSIT | BCA | CMAT |
          BCA Entrance Preparation
        </Text>
        <Text fontSize={["12px", "13px"]} mt="2">
          Test date: Not available
        </Text>
        <Text fontSize={["12px", "13px"]} mt="2">
          Time: 8AM - 10AM
        </Text>
        <Text fontSize={["12px", "13px"]} mt="2">
          Location: Putalisadak, Way to Dillibazaar, Opposite of Citizen Bank
        </Text>
        <Text fontSize={["12px", "13px"]} mt="2" d="flex" gridGap="5px">
          <span>Note:</span>
          <span>
            15% Off on Entrance Preparation Book if you attend the mock test. Up
            to 50% off on Entrance Preparation Classes if you attend the mock
            test.
          </span>
        </Text>
        <Divider orientation="horizontal" h="30px" />
        <form onSubmit={onSubmit}>
          <FormControl mb="6">
            <FormLabel d="flex" gridGap="5px">
              Full Name <Text color="red">*</Text>
            </FormLabel>
            <Input
              fontSize={15}
              py="4"
              variant="flushed"
              colorScheme="primary"
              type="text"
              placeholder="Full Name"
              // isInvalid={errors.last_name ? true : false}
              errorBorderColor="red.500"
              // {...register("last_name", {
              //   required: "Lastname is required",
              // })}
              _placeholder={{ color: "var(--chakra-colors-gray-500)" }}
              autoComplete="off"
            />

            {/* {errors.last_name && (
                  <div className="error">{errors.last_name.message}</div>
                )} */}
          </FormControl>
          <FormControl mb="6">
            <FormLabel d="flex" gridGap="5px">
              Email Address <Text color="red">*</Text>
            </FormLabel>
            <Input
              fontSize={15}
              py="4"
              variant="flushed"
              colorScheme="primary"
              type="text"
              placeholder="Email Address"
              // isInvalid={errors.last_name ? true : false}
              errorBorderColor="red.500"
              // {...register("last_name", {
              //   required: "Lastname is required",
              // })}
              _placeholder={{ color: "var(--chakra-colors-gray-500)" }}
              autoComplete="off"
            />

            {/* {errors.last_name && (
                  <div className="error">{errors.last_name.message}</div>
                )} */}
          </FormControl>
          <FormControl mb="6">
            <FormLabel d="flex" gridGap="5px">
              Address <Text color="red">*</Text>
            </FormLabel>
            <Input
              fontSize={15}
              py="4"
              variant="flushed"
              colorScheme="primary"
              type="text"
              placeholder="Address"
              // isInvalid={errors.last_name ? true : false}
              errorBorderColor="red.500"
              // {...register("last_name", {
              //   required: "Lastname is required",
              // })}
              _placeholder={{ color: "var(--chakra-colors-gray-500)" }}
              autoComplete="off"
            />

            {/* {errors.last_name && (
                  <div className="error">{errors.last_name.message}</div>
                )} */}
          </FormControl>
          <FormControl mb="6">
            <FormLabel d="flex" gridGap="5px">
              Primary Phone Number <Text color="red">*</Text>
            </FormLabel>
            <Input
              fontSize={15}
              py="4"
              variant="flushed"
              colorScheme="primary"
              type="text"
              placeholder="Primary Phone Number"
              // isInvalid={errors.last_name ? true : false}
              errorBorderColor="red.500"
              // {...register("last_name", {
              //   required: "Lastname is required",
              // })}
              _placeholder={{ color: "var(--chakra-colors-gray-500)" }}
              autoComplete="off"
            />

            {/* {errors.last_name && (
                  <div className="error">{errors.last_name.message}</div>
                )} */}
          </FormControl>

          <FormControl mb="6">
            <FormLabel d="flex" gridGap="5px">
              Phone Number
            </FormLabel>
            <Input
              fontSize={15}
              py="4"
              variant="flushed"
              colorScheme="primary"
              type="text"
              placeholder="Phone Number"
              // isInvalid={errors.last_name ? true : false}
              errorBorderColor="red.500"
              // {...register("last_name", {
              //   required: "Lastname is required",
              // })}
              _placeholder={{ color: "var(--chakra-colors-gray-500)" }}
              autoComplete="off"
            />

            {/* {errors.last_name && (
                  <div className="error">{errors.last_name.message}</div>
                )} */}
          </FormControl>
          <FormControl mb="6">
            <FormLabel d="flex" gridGap="5px">
              +2 College <Text color="red">*</Text>
            </FormLabel>
            <Input
              fontSize={15}
              py="4"
              variant="flushed"
              colorScheme="primary"
              type="text"
              placeholder="+2 College"
              // isInvalid={errors.last_name ? true : false}
              errorBorderColor="red.500"
              // {...register("last_name", {
              //   required: "Lastname is required",
              // })}
              _placeholder={{ color: "var(--chakra-colors-gray-500)" }}
              autoComplete="off"
            />

            {/* {errors.last_name && (
                  <div className="error">{errors.last_name.message}</div>
                )} */}
          </FormControl>
          <FormControl mb="6">
            <FormLabel d="flex" gridGap="5px">
              Where are you taking Entrance Preparation Classes ?{" "}
              <Text color="red">*</Text>
            </FormLabel>
            <RadioGroup fontSize={15} name="class_info">
              <Stack spacing={4} direction="column">
                <Radio size="lg" value="1">
                  Binary Entrance Physical Classses
                </Radio>
                <Radio size="lg" value="2">
                  Binary Entrance Online Classses
                </Radio>
                <Radio size="lg" value="3">
                  Self Study
                </Radio>
                <Radio size="lg" value="4">
                  Other
                </Radio>
              </Stack>
            </RadioGroup>
          </FormControl>
          <FormControl mb="6">
            <FormLabel d="flex" gridGap="5px">
              Do you need Entrance Preparation Book ? <Text color="red">*</Text>
            </FormLabel>
            <RadioGroup fontSize={15} name="book_info">
              <Stack spacing={4} direction="column">
                <Radio size="lg" value="1">
                  Yes
                </Radio>
                <Radio size="lg" value="2">
                  No
                </Radio>
                <Radio size="lg" value="3">
                  I already have
                </Radio>
              </Stack>
            </RadioGroup>
          </FormControl>
          <Divider orientation="horizontal" height="20px" />
          <Button
            type="submit"
            minWidth="30%"
            fontSize="15px"
            bg="primary"
            color="#fff"
            py="13px"
            px="18px"
            _hover={{
              bg: "primary",
            }}
          >
            Submit
          </Button>
        </form>
      </RegisterSection>
    </AppLayout>
  );
};

export default MockTestRegister;

const RegisterSection = styled.div`
  max-width: 700px;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 8px 8px 0 0;
  padding: 20px;
  border-top: 5px solid var(--chakra-colors-primary);
`;
