import CourseLayout from "../../components/CourseLayout";
import Layout from "../../components/Layout";
import React, { useEffect, useState } from "react";
import { college } from "../../@types/college";
import { sortByRating } from "../../helpers/helpers";
import { useDispatch } from "react-redux";
import * as actionTypes from "../../redux/actionTypes";
const CSIT: React.FC = () => {
  const [colleges, setColleges] = useState<college[]>([]);
  const dispatch = useDispatch();
  function fetchData() {
    fetch("/data.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        let newArray = data.colleges
          .sort(sortByRating)
          .filter((_: any, i: number) => i < 3);
        newArray = newArray.filter((_: any, index: number) => index < 5);
        setColleges(newArray);
      })
      .catch((e) => console.log("Error reading data", e.message));
  }
  useEffect(() => {
    fetchData();
    dispatch({ type: actionTypes.SET_COURSE, payload: "csit" });
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <Layout>
      <CourseLayout
        colleges={colleges}
        title="Computer Science and Information Technology (CSIT)"
        type="csit"
        description="Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum libero
    quaerat perferendis totam animi odio fuga! Fugiat perferendis eos
    dolorem! Quam deleniti tempore commodi consequatur sapiente! Corporis
    nisi iusto odit, voluptatum dolor ipsum aliquam quaerat. Asperiores
    nesciunt eaque aliquam porro?"
      >
        <div className="divider-md"></div>
      </CourseLayout>
    </Layout>
  );
};

export default CSIT;
