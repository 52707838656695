import Navbar from "../../components/Navbar";
import React, { useEffect } from "react";
import { motion } from "framer-motion";
import styled from "@emotion/styled";
import { useState } from "react";
import { State } from "../../redux/rootReducers";
import { quizStateType } from "../../redux/reducers/quiz";
import * as quizAction from "../../redux/actions/quiz";
import McqCard from "../../components/McqCard";
import {
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
  Tab,
  Heading,
  Spinner,
  Box,
} from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
// import { useRouter } from "next/router";
// import Layout from "src/components/Layout";
import PrivateLayout from "../../components/PrivateLayout";
import { userStateType } from "../../redux/reducers/user";
import { useParams } from "react-router-dom";
import Footer from "../../components/Footer";
import ScheduledMcqCard from "../../components/ScheduledMcqCard";
const Test: React.FC = () => {
  const { course }: any = useParams();
  const dispatch = useDispatch();
  const [index, setIndex] = useState<number>(0);
  const [practiceSets, setPracticeSets] = useState<any[]>([]);
  const [scheduledSets, setScheduledSets] = useState<any[]>([]);
  const quizState = useSelector<State, quizStateType>((state) => state.quiz);
  const { loading, sections } = quizState;
  const userState = useSelector<State, userStateType>((state) => state.user);
  const { isAuthenticated } = userState;
  useEffect(() => {
    const section = `${course}`.toUpperCase();
    dispatch(quizAction.getSection(section));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course]);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  useEffect(() => {
    if (sections.length) {
      setScheduledSets(
        sections?.filter(
          (el: any) => el.display === true && el.scheduled === true
        )
      );
      setPracticeSets(
        sections?.filter(
          (el: any) => el.display === true && el.scheduled === false
        )
      );
    }
  }, [sections]);
  return (
    <PrivateLayout>
      <Navbar active="active" />
      <Container>
        {isAuthenticated ? (
          <>
            <div className="divider-md"></div>
            <h1 className="mocktest-title">
              {`${course}`.toUpperCase()} Mock test
            </h1>
            <Tabs onChange={(index) => setIndex(index)}>
              <TabList className="tab-list">
                <Tab className={index === 0 ? "tab active" : "tab"}>
                  Practice Set
                </Tab>
                <Tab className={index === 1 ? "tab active" : "tab"}>
                  Mocktest
                </Tab>
              </TabList>
              <TabPanels h="fit-content" mt={5} p="0">
                <TabPanel p="0">
                  {loading && (
                    <>
                      <div className="divider-md"></div>
                      <Box d="flex" gridGap="10px" alignItems="center">
                        <Spinner
                          thickness="4px"
                          speed="0.75s"
                          emptyColor="#0e62ac55"
                          color="#0e62ac"
                          h={{ base: "2rem", sm: "2rem", md: "2.5rem" }}
                          w={{ base: "2rem", sm: "2rem", md: "2.5rem" }}
                        />
                        <Heading
                          as="span"
                          fontSize={{ base: "15px", sm: "15px", md: "1.1rem" }}
                          fontWeight={500}
                        >
                          Fetching Practice Sets...
                        </Heading>
                      </Box>
                      <div className="divider-md"></div>
                    </>
                  )}
                  {!loading && practiceSets.length > 0 && (
                    <Grid>
                      {practiceSets.map((el: any, index: number) => (
                        <McqCard
                          key={index}
                          course={el.course?.title}
                          title={el.title}
                        />
                      ))}
                    </Grid>
                  )}
                  {!loading && practiceSets.length === 0 && (
                    <>
                      <div className="divider-md"></div>
                      <h2 className="info">There is no practice sets yet</h2>
                      <div className="divider-md"></div>
                    </>
                  )}
                </TabPanel>
                <TabPanel p="0">
                  {loading && (
                    <>
                      <div className="divider-md"></div>
                      <Box d="flex" gridGap="10px" alignItems="center">
                        <Spinner
                          thickness="4px"
                          speed="0.75s"
                          emptyColor="#0e62ac55"
                          color="#0e62ac"
                          h={{ base: "2rem", sm: "2rem", md: "2.5rem" }}
                          w={{ base: "2rem", sm: "2rem", md: "2.5rem" }}
                        />
                        <Heading
                          as="span"
                          fontSize={{ base: "15px", sm: "15px", md: "1.1rem" }}
                          fontWeight={500}
                        >
                          Fetching Scheduled Tests...
                        </Heading>
                      </Box>
                      <div className="divider-md"></div>
                    </>
                  )}
                  {!loading && scheduledSets.length > 0 && (
                    <Grid>
                      {scheduledSets.map((el: any, index: number) => (
                        <ScheduledMcqCard
                          key={index}
                          course={el.course?.title}
                          title={el.title}
                          start_date={el.start_time}
                          end_date={el.end_time}
                          id={el.id}
                        />
                      ))}
                    </Grid>
                  )}
                  {!loading && scheduledSets.length === 0 && (
                    <>
                      <div className="divider-md"></div>
                      <h2 className="info">
                        There is no live test scheduled. Keep patience weekly
                        online mock test model set will be available soon.
                      </h2>
                      <div className="divider-md"></div>
                    </>
                  )}
                </TabPanel>
              </TabPanels>
            </Tabs>
            <div className="divider"></div>
          </>
        ) : (
          <>
            <div className="divider-md"></div>
            <Box d="flex" gridGap="10px" alignItems="center">
              <Spinner
                thickness="4px"
                speed="0.75s"
                emptyColor="#0e62ac55"
                color="#0e62ac"
                h={{ base: "2rem", sm: "2rem", md: "2.5rem" }}
                w={{ base: "2rem", sm: "2rem", md: "2.5rem" }}
              />
              <Heading
                as="span"
                fontSize={{ base: "15px", sm: "15px", md: "1.1rem" }}
                fontWeight={500}
              >
                Authorizing user, Please wait...
              </Heading>
            </Box>
            <div className="divider-md"></div>
          </>
        )}
      </Container>
      <div className="divider"></div>
      <Footer />
    </PrivateLayout>
  );
};

const Container = styled(motion.div)`
  max-width: 1600px;
  margin: 90px auto 0;
  padding: 0 3rem;
  min-height: 50vh;
  .mocktest-title {
    font-weight: 500;
    font-size: 1.7rem;
    @media only screen and (max-width: 750px) {
      font-size: 1.5rem;
    }
    @media only screen and (max-width: 500px) {
      font-size: 1.3rem;
    }
  }
  .tab-list {
    display: flex;
    gap: 15px;
    position: sticky;
    margin-top: 1.7rem;
    top: 90px;
    background: #e9e9e9;
    padding: 0;
    z-index: 4;
    @media only screen and (max-width: 750px) {
      top: 70px;
      gap: 8px;
    }
  }
  .tab {
    border: none;
    font-family: inherit;
    font-size: 1.1rem;
    font-weight: 500;
    padding: 15px 12px 12px;
    background: transparent;
    border-bottom: 3px solid transparent;
    cursor: pointer;
    height: fit-content;
    white-space: nowrap;
    &.active {
      color: #0e62ac;
      transition: color 0.2s linear;
      border-bottom: 3px solid #0e62ac;
      @media only screen and (max-width: 750px) {
        border-bottom: 2px solid #0e62ac;
      }
    }

    @media only screen and (max-width: 750px) {
      font-size: 1rem;
      padding: 10px 5px;
    }
    @media only screen and (max-width: 500px) {
      font-size: 1rem;
    }
  }
  .info {
    font-weight: 500;
    font-size: 1.1rem;
    color: #00000088;
  }
  @media only screen and (max-width: 750px) {
    padding: 0 2rem;
    margin: 70px auto 0;
  }
  @media only screen and (max-width: 567px) {
    padding: 0 1.5rem;
  }
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 25px;
  @media only screen and (max-width: 927px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media only screen and (max-width: 687px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media only screen and (max-width: 437px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export default Test;
