import styled from "@emotion/styled";

const CollegeCardItem = styled.div`
  background-color: #fff;
  border-radius: 1rem;
  padding: 0.8rem 0.7rem;
  overflow: hidden;
  .college-header-wrapper {
    display: flex;
    align-items: center;
    gap: 1rem;
    .college-logo-container {
      height: 80px;
      width: 80px;
      min-width: 80px;
      overflow: hidden;
      border-radius: 15px;
      .college-logo {
        object-fit: contain;
        vertical-align: middle;
        width: 100%;
        height: 100%;
      }
    }
    .college-contents {
      .college-name {
        a {
          font-size: 1rem;
          font-weight: 600;
          color: #0e62ac;
        }
      }
    }
  }
  .college-location-container {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    span {
      font-size: 0.95rem;
      text-transform: capitalize;
    }
  }
`;
export default CollegeCardItem;
