import styled from "@emotion/styled";
const Layout = styled.div`
  width: 100%;
  .course-container {
    user-select: none;
    max-width: 1600px;
    margin: 90px auto 0 auto;

    .course-header {
      position: relative;
      height: 20rem;
      &::before {
        content: "";
        position: absolute;
        background: #00000090;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
      }
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
      .header-content {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        z-index: 3;
        &-wrapper {
          max-width: 1600px;
          margin: 0 auto;
          padding: 1.5rem 3rem;
          .course-title {
            font-size: 2.3rem;
            font-weight: 700;
            margin-top: 0.8rem;
            max-width: 65rem;
            text-transform: uppercase;
            color: #eee;
            @media only screen and (max-width: 750px) {
              font-size: 2rem;
            }
            @media only screen and (max-width: 567px) {
              font-size: 1.8rem;
            }
            @media only screen and (max-width: 400px) {
              font-size: 1.5rem;
            }
            @media only screen and (max-width: 250px) {
              font-size: 1.2rem;
            }
          }
          @media only screen and (max-width: 750px) {
            padding: 1.5rem 1.8rem;
          }
          @media only screen and (max-width: 567px) {
            padding: 1.3rem 1.5rem;
          }
        }
      }
    }
    .course-body {
      height: fit-content;
      padding: 0 4rem;
      &-wrapper {
        position: relative;
        .course-details {
          z-index: 5;
          padding: 1.5rem;
          border-radius: 0.7rem;
          background: #e2e3e3;
          transform: translatey(-3rem);
          display: flex;
          flex-direction: column;
          gap: 1.5rem;
          .top-grid-container {
            display: flex;
            flex-wrap: wrap;
            gap: 1.8rem;
            justify-content: center;
            .item {
              display: flex;
              width: 200px;
              flex-direction: column;
              align-items: center;
              gap: 0.5rem;
              span {
                font-size: 1rem;
                color: #222;
                text-align: center;
                font-weight: 500;
              }
              @media only screen and (max-width: 847px) {
                width: 180px;
              }
            }
          }
        }
      }
      @media only screen and (max-width: 750px) {
        padding: 1.5rem 1.8rem;
      }
      @media only screen and (max-width: 567px) {
        padding: 1.5rem;
      }
      @media only screen and (max-width: 250px) {
        padding: 1.3rem 0rem;
      }
    }
    .loading-text {
      display: flex;
      gap: 8px;
      align-items: center;
      margin: 1.5rem 0;
      span {
        font-size: 0.7rem;
        font-weight: 400;
      }
    }
    .swiper-container {
      padding-bottom: 25px;
      .swiper-pagination {
        bottom: 5px;
        .swiper-pagination-bullet {
          background: #0e62ac !important;
          height: 6px;
          width: 20px;
          border-radius: 5px;
        }
      }
    }
    .button-container {
      display: flex;
      justify-content: flex-end;
      margin-top: 1rem;
    }
    @media only screen and (max-width: 750px) {
      margin: 70px auto 0 auto;
    }
  }
`;
export default Layout;
