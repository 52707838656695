import { Button } from "@chakra-ui/button";
import { FormControl } from "@chakra-ui/form-control";
import { Input, InputGroup, InputLeftElement } from "@chakra-ui/input";
import { HStack, Stack, Text } from "@chakra-ui/layout";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import { chakra } from "@chakra-ui/system";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
type propsType = {
  isOpen: boolean;
};
/* eslint-disable import/first */
import { FaUserAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import * as actionTypes from "../../redux/actionTypes";
const CFaUserAlt = chakra(FaUserAlt);
import * as userAction from "../../redux/actions/user";
import { PinInput, PinInputField } from "@chakra-ui/pin-input";
import { State } from "../../redux/rootReducers";
import { userStateType } from "../../redux/reducers/user";
const Verification: React.FC<propsType> = ({ isOpen }) => {
  const dispatch = useDispatch();
  const {
    profile: { email },
    isAuthenticated,
  } = useSelector<State, userStateType>((state) => state.user);
  const {
    handleSubmit,
    register,
    reset,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({ mode: "all" });
  const [success, setSuccess] = useState<boolean>(false);
  const handleClose = () => {
    dispatch({ type: actionTypes.HIDE_VERIFICATION });
    setSuccess(false);
    reset();
  };
  const [verification_code, setVerificationCode] = useState<
    number | string | any
  >("");
  const onSubmit = async (values: any) => {
    const formData = new FormData();
    if (!success) {
      formData.set("email", values.email);
      formData.delete("verification_code");
      const res: any = await dispatch(userAction.sendOtpForVerify(formData));
      if (res.status === 200) {
        setSuccess(true);
      }
    } else {
      formData.set("email", values.email);
      formData.set("verification_code", verification_code);
      const res: any = await dispatch(userAction.verifyUser(formData));
      if (res.status === 200) {
        dispatch({ type: actionTypes.HIDE_VERIFICATION });
        dispatch({ type: actionTypes.VERIFICATION_SUCCESS });
        reset();
      }
    }
  };
  const handleChange = (value: any) => {
    setVerificationCode(value);
  };
  useEffect(() => {
    if (email) {
      setValue("email", email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);
  return isAuthenticated && email ? (
    <Modal
      blockScrollOnMount={true}
      size="lg"
      isOpen={isOpen}
      onClose={() => {}}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Verification</ModalHeader>
        <ModalCloseButton onClick={handleClose} />
        <ModalBody minH="180px">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={4} p="1rem" backgroundColor="whiteAlpha.900">
              {!success && (
                <FormControl mb="5">
                  <InputGroup>
                    <InputLeftElement
                      top={1.5}
                      pointerEvents="none"
                      children={<CFaUserAlt h="full" color="gray.500" />}
                    />
                    <Input
                      fontSize={15}
                      py="6"
                      isInvalid={errors.email ? true : false}
                      errorBorderColor="red.500"
                      placeholder="Email address"
                      readOnly
                      _placeholder={{ color: "var(--chakra-colors-gray-500)" }}
                      autoComplete="off"
                      {...register("email", {
                        required: "Email is required",
                        pattern: {
                          value:
                            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                          message: "Email is invalid",
                        },
                      })}
                      defaultValue={email}
                    />
                  </InputGroup>

                  {errors.email && (
                    <div className="error">{errors.email.message}</div>
                  )}
                </FormControl>
              )}
              {success && (
                <>
                  <Text fontSize={15}>Enter Verification Code *</Text>
                  <FormControl mb="5">
                    <HStack>
                      <PinInput
                        type="number"
                        placeholder="0"
                        otp={true}
                        onChange={handleChange}
                        isInvalid={errors.verification_code ? true : false}
                        errorBorderColor="red.500"
                      >
                        <PinInputField
                          _placeholder={{
                            color: "var(--chakra-colors-gray-500)",
                          }}
                          border="1px solid var(--chakra-colors-gray-500)"
                          fontSize={15}
                          py="6"
                        />
                        <PinInputField
                          _placeholder={{
                            color: "var(--chakra-colors-gray-500)",
                          }}
                          fontSize={15}
                          py="6"
                        />
                        <PinInputField
                          _placeholder={{
                            color: "var(--chakra-colors-gray-500)",
                          }}
                          fontSize={15}
                          py="6"
                        />
                        <PinInputField
                          _placeholder={{
                            color: "var(--chakra-colors-gray-500)",
                          }}
                          fontSize={15}
                          py="6"
                        />
                      </PinInput>
                    </HStack>
                    {!verification_code && (
                      <div className="error">OTP Code is required</div>
                    )}
                  </FormControl>
                </>
              )}
              <Button
                borderRadius={0}
                type="submit"
                variant="solid"
                isLoading={isSubmitting}
                width="full"
                fontSize={15}
                py="6"
                backgroundColor="primary"
                color="whiteAlpha.800"
                rounded="md"
                _hover={{
                  color: "var(--chakra-colors-gray-500)",
                  backgroundColor: "#ddd",
                }}
              >
                {success ? "Confirm" : "Send OTP"}
              </Button>
            </Stack>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  ) : null;
};

export default Verification;
