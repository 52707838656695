import React from "react";
import Layout from "../Layout";
import Navbar from "../Navbar";
import Footer from "../Footer";
import styled from "@emotion/styled";
const AppLayout: React.FC = ({ children }) => {
  return (
    <Layout>
      <Navbar active="active" />
      <AppContainer>{children}</AppContainer>
      <div className="divider"></div>
      <Footer />
    </Layout>
  );
};

export default AppLayout;

const AppContainer = styled.div`
  width: 100%;
  margin: 90px auto 0 auto;
  padding: 0 4rem;
  min-height: 80vh;
  @media only screen and (max-width: 750px) {
    padding: 0 2rem;
    margin: 70px auto 0 auto;
  }
  @media only screen and (max-width: 567px) {
    padding: 0 1.5rem;
  }
`;
