import * as actionTypes from "../actionTypes";
export type blogStateType = {
  blogs: any[];
  singleBlog: any;
  error: boolean;
};
const initState: blogStateType = {
  blogs: [],
  singleBlog: {},
  error: false,
};
const blogReducer = (state: blogStateType = initState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET_NOTICE:
      return { ...state, notices: payload };
    case actionTypes.GET_SINGLE_BLOG_REQUEST: {
      return {
        ...state,
        singleBlog: {},
        error: false,
      };
    }
    case actionTypes.GET_SINGLE_BLOG_SUCCESS: {
      return {
        ...state,
        singleBlog: payload,
        error: false,
      };
    }
    case actionTypes.GET_SINGLE_BLOG_FAILED: {
      return {
        ...state,
        singleBlog: {},
        error: true,
      };
    }
    default:
      return state;
  }
};
export default blogReducer;
