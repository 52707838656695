import React from "react";
import Footer from "../Footer";
import Layout from "./styled";

const NoticeLayout: React.FC = ({ children }) => {
  return (
    <Layout>
      {children}
      <Footer />
    </Layout>
  );
};

export default NoticeLayout;
