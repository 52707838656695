import styled from "@emotion/styled";
import { motion } from "framer-motion";
const GalleryCard = styled(motion.div)`
  overflow: hidden;
  border: 4px solid #ddd;
  transition: all 0.1s linear;
  &:hover {
    border: 4px solid #0e62ac88;
  }
  &.gallery-item {
    &-1,
    &-4,
    &-6,
    &-5,
    &-8,
    &-11,
    &-12 {
      grid-row: span 2;
      @media only screen and (max-width: 750px) {
        grid-row: span 1;
      }
    }
    &-2,
    &-7 {
      grid-row: span 3;
      @media only screen and (max-width: 750px) {
        grid-row: span 1;
      }
    }
  }
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;
export default GalleryCard;
