import React from "react";
import { Tooltip } from "@chakra-ui/tooltip";
import GalleryCard from "./styled";
import { Image } from "@chakra-ui/image";
import loader from "../../assets/images/loader.gif";
type Props = {
  image: string;
  title?: string;
  index: number;
};
const GalleryItem: React.FC<Props> = ({ image, title, index }) => {
  return (
    <GalleryCard className={`gallery-item-${index}`}>
      <Tooltip
        label={title}
        bg="var(--chakra-colors-primary)"
        color="#eee"
        fontSize="1rem"
      >
        <a href={image} aria-label={title ? title : "Binary Entrance Gallery"}>
          <Image
            src={image}
            alt={title ? title : "Binary Entrance Gallery Photo"}
            fallbackSrc={loader}
          />
        </a>
      </Tooltip>
    </GalleryCard>
  );
};

export default GalleryItem;
