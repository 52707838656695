import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import Navbar from "../../components/Navbar";
import * as quizAction from "../../redux/actions/quiz";
import {
  Box,
  // Divider,
  Flex,
  Heading,
  List,
  ListItem,
  Text,
} from "@chakra-ui/layout";
import "katex/dist/katex.min.css";
import { Link, useNavigate } from "react-router-dom";
import { Spinner } from "@chakra-ui/spinner";
import { FiArrowLeft } from "react-icons/fi";
import { Button } from "@chakra-ui/button";
import Layout from "../../components/Layout";
import { BsArrowLeft } from "react-icons/bs";

// import { userStateType } from "src/redux/reducers/user";

const Result: React.FC = () => {
  const navigate = useNavigate();
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const fetchResult = async () => {
    setLoading(true);
    const res = await quizAction.fetchPhysicalMockTestResult();
    if (res?.status === 200) {
      setLoading(false);
      setData(res?.data);
    } else {
      setLoading(false);
      setData([]);
    }
  };
  useEffect(() => {
    fetchResult();
  }, []);
  return (
    <Layout>
      <Navbar active="active" />
      <Container>
        <Box maxW="700px" mx="auto">
          <Box w="full" mb="15px">
            <Button
              bg="transparent"
              px="0"
              py="0"
              h="fit-content"
              leftIcon={
                <BsArrowLeft
                  fontSize="25px"
                  color="var(--chakra-colors-primary)"
                />
              }
              _hover={{ background: "transparent" }}
              onClick={() => navigate(-1)}
              minW="fit-content"
              color="primary"
            >
              Go back
            </Button>
          </Box>
          <Text
            mt="15px"
            mb="20px"
            fontSize={{ base: "16px", sm: "18px", md: "20px" }}
          >
            Physical mocktest result.
          </Text>
          {loading ? (
            <Box d="flex" h="fit-content" gridGap="10px" alignItems="center">
              <Spinner
                thickness="4px"
                speed="0.75s"
                emptyColor="#0e62ac55"
                color="#0e62ac"
                h={{ base: "2rem", sm: "2rem", md: "2.5rem" }}
                w={{ base: "2rem", sm: "2rem", md: "2.5rem" }}
              />
              <Heading
                as="span"
                fontSize={{ base: "15px", sm: "15px", md: "1rem" }}
                fontWeight={500}
              >
                Loading result...
              </Heading>
            </Box>
          ) : data?.filter((item) => item.display === true).length === 0 ? (
            <Heading
              as="span"
              fontSize={{ base: "15px", sm: "15px", md: "1rem" }}
              color="red.500"
              fontWeight={500}
            >
              No result found.
            </Heading>
          ) : (
            <List spacing={5}>
              {data
                ?.filter((item) => item.display === true)
                .map((item: any, index) => (
                  <ListItem key={`${index}`}>
                    <Link to={`/result/physical/${item.id}`}>
                      <ScoreCard>
                        <h2 className="title">{item?.title}</h2>
                      </ScoreCard>
                    </Link>
                  </ListItem>
                ))}
            </List>
          )}
        </Box>
      </Container>
    </Layout>
  );
};

export default Result;
const Container = styled.div`
  max-width: 1600px;
  margin: 90px auto 0;
  padding: 2rem 5rem;

  @media only screen and (max-width: 750px) {
    padding: 2rem 3rem;
    margin: 70px auto 0;
  }
  @media only screen and (max-width: 678px) {
    margin: 70px auto 0;
  }
  @media only screen and (max-width: 567px) {
    padding: 20px;
  }
`;

export const ScoreCard = styled(Box)`
  background: #ffffff;
  border-radius: 8px;
  padding: 15px 20px;
  .title {
    font-size: 20px;
    color: var(--chakra-colors-primary);
    font-weight: 500;
    margin-bottom: 8px;
    @media only screen and (max-width: 678px) {
      font-size: 18px;
    }
    @media only screen and (max-width: 567px) {
      font-size: 16px;
    }
  }
  .score {
    font-size: 17px;
    @media only screen and (max-width: 678px) {
      font-size: 16px;
    }
    @media only screen and (max-width: 567px) {
      font-size: 15px;
    }

    .bold {
      font-weight: 500;
    }
  }
`;
