import Api from "../../services/api";
import * as actionTypes from "../actionTypes";
export const getGallery = () => async (dispatch: any) => {
  try {
    const api = new Api(false);
    const res = await api.get(`/api/gallery/`, {});
    dispatch({
      type: actionTypes.SET_GALLERY,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: actionTypes.SET_GALLERY,
      payload: [],
    });
  }
};
